import { ref } from 'vue'

const search = ref('')

export const SEARCH_ID = 'global-search-input-id'

export const focusGlobalSearch = () => {
  const el = document.getElementById(SEARCH_ID)
  if (el?.focus) el.focus()
}

export default () => search
