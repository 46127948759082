export const isEqual = (obj1, obj2) => {
  let type = getType(obj1)
  if (type !== getType(obj2)) return false
  if (type === 'array') return isEqualArrays(obj1, obj2)
  if (type === 'object') return isEqualObjects(obj1, obj2)
  if (type === 'function') return isEqualFunctions(obj1, obj2)
  return isEqualPrimitives(obj1, obj2)
}

export const isEqualSoft = (obj1, obj2) => {
  let type = getType(obj1)

  if (type === 'array' && type === getType(obj2)) return isEqualArrays(obj1, obj2)
  if (type === 'object' && type === getType(obj2)) return isEqualObjects(obj1, obj2)
  if (type === 'function' && type === getType(obj2)) return isEqualFunctions(obj1, obj2)

  if (type === 'string') return isEqualPrimitives(obj1, String(obj2))
  if (type === 'number') return isEqualPrimitives(obj1, Number(obj2))
  if (type === 'boolean') return isEqualPrimitives(obj1, Boolean(obj2))

  return isEqualPrimitives(obj1, obj2)
}

const getType = obj => {
  return Object.prototype.toString.call(obj).slice(8, -1).toLowerCase()
}

export const isEqualArrays = (obj1, obj2) => {
  // Check length
  if (obj1.length !== obj2.length) return false

  // Check each item in the array
  for (let i = 0; i < obj1.length; i++) {
    if (!isEqual(obj1[i], obj2[i])) return false
  }

  // If no errors, return true
  return true
}

export const isEqualObjects = (obj1, obj2) => {
  if (Object.keys(obj1).length !== Object.keys(obj2).length) return false

  // Check each item in the object
  for (let key in obj1) {
    if (Object.prototype.hasOwnProperty.call(obj1, key)) {
      if (!isEqual(obj1[key], obj2[key])) return false
    }
  }

  // If no errors, return true
  return true
}

export const isEqualFunctions = (obj1, obj2) => {
  return obj1.toString() === obj2.toString()
}

export const isEqualPrimitives = (obj1, obj2) => {
  return obj1 === obj2
}

export default isEqual
