<template>
  <div v-if="model" class="flex flex-col gap-4">
    <UIInput v-model="model.naam" label="Naam" type="text" class="w-full max-w-sm" placeholder="Naam ..." />
    <UIInput v-model="model.nummerplaat" mask="[A-Za-z0-9]{1}-[A-Za-z]{3}-[A-Za-z0-9]{3}" label="Nummerplaat" type="text" class="w-full max-w-sm" placeholder="Nummerplaat ..." />
    <UIInput v-model="model.VIN" :disabled="isEdit" label="VIN Nummer" type="text" class="w-full max-w-sm" placeholder="VIN Nummer ..." />
    <UIInput v-model="model.teken" label="Teken" type="number" class="w-full max-w-sm" placeholder="Teken ..." />
    <UISelectFixed type="AUTOMERK" class="w-full max-w-sm" v-model="model.merk" />
    <UIInput v-model="model.model" label="Model" type="text" class="w-full max-w-sm" placeholder="Model ..." />
    <UIInput v-if="!store.getters.isKantoorPermission" v-model="model.kilometerstand" label="Kilometerstand" type="number" class="w-full max-w-sm" placeholder="Kilometerstand ..." />
    <button @click="handleSubmit" class="btn success mt-4">Opslaan</button>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, watch } from 'vue'
import { useVModel } from '@vueuse/core'

import UIInput from '@/components/UI/Input/Input'
import UISelectFixed from '@/components/UI/Select/Fixed'
import useApi from '@/hooks/useApi'
import { useStore } from 'vuex'

const props = defineProps({
  callback: Function,
  modelValue: {
    type: Object,
    default() {
      return {
        id: null,
        naam: '',
        nummerplaat: '',
        VIN: '',
        teken: null,
        merk: 'Opel',
        model: 'Vivaro',
        kilometerstand: 1,
      }
    },
  },
})

const store = useStore()
const api = useApi()
const emit = defineEmits(['update:modelValue'])
const model = useVModel(props, 'modelValue', emit, { deep: true, passive: true })

const isEdit = model.value.id > 0
watch(() => model.value.merk, () => {
  model.value.model = ''
})

const handleSubmit = () => {
  api(isEdit ? 'WAGEN_EDIT' : 'WAGEN_CREATE', model).then(() => {
    if (typeof props.callback === 'function') {
      props.callback()
    }
    emit('close', true)
  })
}
</script>
