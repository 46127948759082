<template>
  <span class="input--wrapper w-full">
    <UIInputLabel v-if="label" :focussed="focussed" :id="id" :required="required">
      <span class="inline-flex flex-row gap-1">
        <span>{{ label }}</span>
        <button v-if="info" @click.prevent.stop="() => handleInfoClicked()"><i class="fa fas fa-info-circle"></i></button>
      </span>
    </UIInputLabel>
    <div class="flex flex-row gap-2">
      <component
        :is="textarea ? 'textarea' : 'input'"
        :ref="el => (inputRef = el)"
        v-bind="Object.assign({}, $attrs, { placeholder, id })"
        :placeholder="typeof placeholder === 'undefined' ? label : placeholder"
        :id="id"
        :type="innerType"
        @focus="onFocus"
        @blur="onBlur"
        @click="$emit('click', $event)"
        @keypress="$emit('keypress', $event)"
        @keydown="onKeyDown"
        @input="onInput"
        class="input w-full mt-0 z-0 py-2"
        :style="textarea ? `height: ${height}px` : ''"
        :value="model"
      />
      <button type="button" class="w-6" v-if="type === 'password' && !hiddenEye" @click="innerType = (innerType === 'password' ? 'text' : 'password')">
        <i class="fas" :class="{ 'fa-eye': innerType === 'password', 'fa-lock': innerType !== 'password' }"></i>
      </button>
    </div>
    <small v-if="details" class="block mt-0 pt-0 input-details">{{ details }}</small>
  </span>
</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted, computed, watch } from 'vue'
import { useVModel } from '@vueuse/core'
import { v4 as uuidv4 } from 'uuid'
import UIInputLabel from '@/components/UI/Input/Label.vue'
import useNotification from '@/hooks/useNotification'

const props = defineProps({
  label: String,
  details: String,
  info: String,
  required: Boolean,
  id: {
    type: [Number, String],
    default: uuidv4,
  },
  type: {
    type: String,
    default: 'text',
  },
  rows: {
    type: [String, Number],
    default: 2,
  },
  placeholder: {
    type: String,
    default: undefined,
  },
  textarea: Boolean,
  transform: Function,
  hiddenEye: Boolean,
  modelValue: {
    type: [Number, String],
    default() {
      return ''
    },
  },
})

const innerType = ref(props.type)
watch(() => props.type, (val) => { innerType.value = val })

const inputRef = ref(null)
const focussed = ref(false)
const emit = defineEmits(['focus', 'blur', 'click', 'keydown', 'keypress', 'update:modelValue'])
const model = useVModel(props, 'modelValue', emit, { deep: true, passive: true })

// START --- Height calculation Textarea
const minimumHeight = computed(() => Math.max(2, parseInt(props.rows, 10)) * 25)
const height = ref(Number(minimumHeight.value))
const handleTextAreaHeight = () => {
  if (props.textarea && inputRef.value) {
    height.value = Math.min(250, Math.max(minimumHeight.value, inputRef.value.scrollHeight || 0))
    // if (!vModels.modelValue.value.toString()) {
    //   height.value = 50
    // }
    inputRef.value.style.height = height.value
  }
}
// END --- Height calculation Textarea

const onFocus = $event => {
  focussed.value = true
  emit('focus', $event)
}

const onBlur = $event => {
  focussed.value = false
  emit('blur', $event)
}

const onInput = (ev) => {
  let val = ev.target.value || ''
  if (props.type === 'number') {
    val = ev.target.value.replaceAll(',', '.').replaceAll('e', '').replace(/[^-0-9.]/gi, '')
  }
  val = String(val || '')
  if (typeof props.transform === 'function') {
    val = props.transform(val)
  }
  model.value = val
  handleTextAreaHeight()
}

const handleInfoClicked = () => {
  if (!props.info?.length) return
  useNotification(props.info, 'info')
}

const onKeyDown = function(ev) {
  if (ev.keyCode === 13) {
    if (typeof ev.blur === 'function') ev.blur()
    else onBlur(ev)
  }
  emit('keydown', ev)
}

if (props.textarea) onMounted(handleTextAreaHeight)
</script>

<style scoped>
.input-details {
  font-size: 12px;
}
</style>
