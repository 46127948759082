import { ref } from 'vue'

export const modal = ref(null)

export const close = () => (modal.value = null)

export const open = (type, props = {}, options = {}) => {
  modal.value = type ? { type, props, options } : null
  return close
}

export default VueApp => {
  VueApp.provide('modal', modal)
}
