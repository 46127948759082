<template>
  <div class="flex flex-col gap-1">
    <div v-for="(number) in yours" :key="number">
      <button @click="handleOpen(number)">{{ number }}</button>
    </div>

    <hr v-if="others?.length">

    <div v-for="(number) in others" :key="number">
      <button @click="handleOpen(number)">{{ number }}</button>
    </div>

    <button @click="handleOpen('test')">test</button>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'
import { useStore } from 'vuex'
import useSearchGlobal from '@/hooks/useSearchGlobal'
import { isEqualSoft } from '@/functions/isEqual'

const props = defineProps({
  callback: Function,
  calls: {
    type: Object,
    default() {
      return {}
    },
  },
})

const emit = defineEmits(['close'])
const store = useStore()

const searchGlobal = useSearchGlobal()

const yours = computed(() => (props.calls || [])
  .filter(el => el?.number && isEqualSoft(el?.agent_id, store.getters.currentUserId))
  .map(el => el.number)
  .filter((el, index, self) => self.indexOf(el) === index),
)

const others = computed(() => (props.calls || [])
  .filter(el => el?.number && !isEqualSoft(el?.agent_id, store.getters.currentUserId))
  .map(el => el.number)
  .filter((el, index, self) => self.indexOf(el) === index),
)

const handleOpen = (number) => {
  searchGlobal.value = number
  if (typeof props.callback === 'function') {
    props.callback(number)
  }
  emit('close', true)
}
</script>
