<template>
  <div v-if="!store.getters.hasMasterPermission">
    <small class="text-red-500 font-bold">Geen rechten om betalingen aan te passen</small>
  </div>
  <div v-else class="flex flex-col gap-3">
    <UISelectChauffeur class="w-full max-w-sm" active label v-model="betaling.user_id" />
    <UISelectFixed :disabled="!store.getters.hasKantoorPermission" type="BETAALMETHODE" class="w-full max-w-sm" label="Betaalmethode" v-model="betaling.methode" />
    <UIInputDatePicker label="Betaal datum" class="text-left" v-model:date="betaling.date" />
    <UIInput :disabled="betaling.date_terugbetaling" v-model="betaling.bedrag" label="Bedrag betaald" type="number" class="w-full max-w-sm" placeholder="Bedrag ..." />
    <UIInput textarea v-model="betaling.opmerking" label="Opmerking over de ontvangen betaling" rows="4" class="w-full max-w-sm mb-2" placeholder="Eventuele opmerking ..." />

    <button v-if="!showTerugbetaling" @click.stop="showTerugbetaling = true" class="inline-block text-left w-auto">
      <UIInputLabel>Terugbetaling +</UIInputLabel>
    </button>
    <UIInputLabel v-else>Terugbetaling</UIInputLabel>
    <div class="card boder-yellow-500 -mt-2 mb-4" v-if="showTerugbetaling">
      <UIInput v-model="betaling.bedrag_terugbetaald" label="Bedrag terugbetaald" type="number" class="w-full max-w-sm" placeholder="Bedrag terugbetaling ..." />
      <UIInputDatePicker label="Terugbetaling datum" class="text-left" v-model:date="betaling.date_terugbetaling" />
    </div>

    <button class="btn success inline-block" @click="handleOpslaan">Opslaan</button>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue'
import { useStore } from 'vuex'
import { useVModel } from '@vueuse/core'

import useApi from '@/hooks/useApi'
import { dateString, isDate } from '@/functions/formatDate'

import UIInput from '@/components/UI/Input/Input'
import UIInputDatePicker from '@/components/UI/Input/DatePicker.vue'
import UISelectFixed from '@/components/UI/Select/Fixed'
import UISelectChauffeur from '@/components/UI/Select/Chauffeur.vue'
import UIInputLabel from '@/components/UI/Input/Label.vue'

const props = defineProps({
  callback: {
    type: Function,
    required: true,
  },
  modelValue: {
    type: Object,
    default() {
      return {
        id: null,
        user_id: null,
        date: null,
        date_terugbetaling: null,
        bedrag: null,
        bedrag_terugbetaald: null,
        methode: null,
        opmerking: null,
      }
    },
  },
})

const store = useStore()
const emit = defineEmits(['close'])
const vModel = useVModel(props, 'modelValue', emit, { deep: true, passive: true })

const showTerugbetaling = ref(!!(vModel.value.bedrag_terugbetaald || vModel.value.date_terugbetaling))

const betaling = ref({
  id: vModel.value.id,
  user_id: vModel.value.user_id,
  date: dateString(vModel.value.date && isDate(vModel.value.date) ? new Date(vModel.value.date) : new Date()),
  date_terugbetaling: vModel.value.date_terugbetaling ? dateString(vModel.value.date_terugbetaling) : null,
  bedrag: Math.max(0, vModel.value.bedrag),
  bedrag_terugbetaald: Math.max(0, vModel.value.bedrag_terugbetaald),
  methode: vModel.value.methode || 'CASH',
  opmerking: vModel.value.opmerking || '',
})

const api = useApi()
const handleOpslaan = () => {
  if (!store.getters.hasKantoorPermission) {
    betaling.value.methode = 'CASH'
  }
  api('BETALING_EDIT', betaling).then(() => {
    Object.entries(betaling.value).forEach(([key, value]) => {
      vModel.value[key] = value
    })
    emit('close')
    if (typeof props.callback === 'function') {
      props.callback()
    }
  })
}
</script>
