import { useRoute, useRouter } from 'vue-router'

export default (queryName, queryValue, _router, _route) => {
  const route = _route || useRoute()
  const router = _router || useRouter()
  const routeQuery = route?.query || {}

  if (routeQuery[queryName] === queryValue) return
  if (queryValue === null || queryValue === '' || typeof queryValue === 'undefined') {
    router.push({ query: { ...routeQuery, [queryName]: undefined } })
  } else {
    router.push({ query: { ...routeQuery, [queryName]: queryValue } })
  }
}
