<template>
  <div v-if="model" class="flex flex-col gap-4 max-w-sm">
    <UISelectFixed type="BLACKLIST_WHITELIST_TYPE" class="w-full" v-model="model.type" />
    <UIInput v-model="model.value" :label="`Waarde (${model.type.replace('_', ' ')})`" class="w-full" placeholder="Waarde ..." />
    <UIInput textarea rows="4" v-model="model.opmerking" label="Opmerking" class="w-full" placeholder="Opmerking ..." />

    <div :key="model.count" class="flex flex-col gap-2">
      <UIInput v-if="apiData.whitelist === 'VIP' && model.count !== 99999" type="number" v-model="model.count" label="VIP Experiences" class="w-full" placeholder="Aantal keer VIP" />
      <UIInputCheckbox v-if="apiData.whitelist === 'VIP'" :modelValue="!!(model.count === 99999)" @update:modelValue="() => model.count = model.count === 99999 ? 0 : 99999" label="Altijd VIP?" class="w-full" />
    </div>

    <button @click="handleSubmit" class="btn success mt-4">Opslaan</button>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'
import { useVModel } from '@vueuse/core'
import clone from 'just-clone'

import UIInputCheckbox from '@/components/UI/Input/Checkbox'
import UIInput from '@/components/UI/Input/Input'
import UISelectFixed from '@/components/UI/Select/Fixed'
import useApi from '@/hooks/useApi'

const props = defineProps({
  apiData: {
    type: Object,
    default() { return {} },
  },
  callback: Function,
  modelValue: {
    type: Object,
    default() {
      return {
        id: null,
        type: 'EMAIL',
        value: '',
        opmerking: '',
      }
    },
  },
})

const api = useApi()
const emit = defineEmits(['update:modelValue'])
const model = useVModel(props, 'modelValue', emit, { deep: true, passive: true })
const isEdit = model.value.id > 0

const handleSubmit = () => {
  const data = { ...clone(model.value), ...props.apiData }

  api(isEdit ? 'WHITELIST_BLACKLIST_EDIT' : 'WHITELIST_BLACKLIST_CREATE', data).then(() => {
    if (typeof props.callback === 'function') {
      props.callback()
    }
    emit('close', true)
  })
}
</script>
