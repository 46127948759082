import { ref } from 'vue'

export const notification = ref({})

export const set = (objOrHtml, type) => {
  const newNotification = typeof objOrHtml === 'object' ? objOrHtml : { html: objOrHtml }
  if (type) {
    newNotification.type = type
  }
  notification.value = newNotification
}

export const clear = () => { notification.value = {} }

// let timeout = null

export default VueApp => {
  /*
  watch(notification, n => {
    const keys = Object.keys(n.value)
    if (!keys.length) return
    timeout = setTimeout(() => {
      clearTimeout(timeout)
      notification.value = {}
    }, n.value.delay || 12000)
  })
  */
  VueApp.provide('notification', notification)
}
