import { useRoute, useRouter } from 'vue-router'
import { watch, ref } from 'vue'
import useRouteQuerySet from './_useRouteQuerySet'

export default (checkFn, parseFn, defaultValueFn) => (queryName, defaultValue = defaultValueFn()) => {
  const route = useRoute()
  const router = useRouter()
  const defaultValueSafe = checkFn(defaultValue) || defaultValue === null ? defaultValue : defaultValueFn()
  const getValueFromQuery = () => parseFn(checkFn(route.query[queryName]) ? route.query[queryName] : defaultValueSafe)
  const reactiveValue = ref(getValueFromQuery())
  const setRoute = val => useRouteQuerySet(queryName, !val ? null : parseFn(val), router, route)
  watch(() => reactiveValue.value, setRoute)
  watch(() => route.query[queryName], () => reactiveValue.value = getValueFromQuery())
  setRoute(reactiveValue.value)
  // console.log(queryName, reactiveValue.value)
  return reactiveValue
}
