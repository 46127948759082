<template>
  <div class="flex flex-col gap-4">
    <div v-if="loading">Loading...</div>
    <div v-else-if="actiesAdmin">
      <button @click="() => handleActivatie(!data?.is_passagier_lijst)" class="btn" :class="{ error: data?.is_passagier_lijst, success: !data?.is_passagier_lijst }">
        Passagier lijst {{ data?.is_passagier_lijst ? 'desactiveren' : 'activeren' }}
      </button>
    </div>

    <div v-if="!loading && data?.is_passagier_lijst">
      <UITableResponsive :items="data?.list" :headers="['Ingestapt', 'Naam']" :suffix-headers="actiesAllowed ? ['Acties'] : []" no-data-text="Geen passagier op de lijst">
        <template v-slot:colgroup>
          <colgroup>
            <col class="w-24" />
            <col />
            <col class="w-auto" />
          </colgroup>
        </template>
        <template v-slot:item-Ingestapt="{ item }">
          <div class="text-center py-1">
            <UICheckbox
              class="mx-auto"
              :model-value="item.is_ingestapt"
              :disabled="disabled.includes(item.id)"
              @update:model-value="($event) => handleIngestapt(item.id, $event)"
            />
          </div>
        </template>
        <template v-slot:item-Naam="{ item }">
          <div>
            <UIInput class="w-full min-w-32 cursor-not-allowed" v-model="item.naam" disabled />
            <small class="pl-2" v-if="item.chauffeur_id && item.is_ingestapt"><b>Aangeduid door</b>: {{ store.getters.chauffeur(item.chauffeur_id)?.label }}</small>
          </div>
        </template>
        <template v-slot:item-Acties="{ item }">
          <div class="flex flex-row gap-2">
            <button @click="() => handleDelete(item.id)" class="btn error">Verwijderen</button>
          </div>
        </template>
      </UITableResponsive>
    </div>

    <div class="border border-dashboard-dark mt-4" v-if="actiesAllowed">
      <h2 class="bg-dashboard-dark text-white p-2">Passagier toevoegen</h2>

      <div class="p-4 flex flex-col items-start gap-4 max-w-3xl">
        <UIInput v-model.trim="form.naam" placeholder="Naam" />
        <button @click="() => handleOpslaan()" class="btn success">Opslaan</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'

import useApi from '@/hooks/useApi'
import useGetApi from '@/hooks/useGetApi'

import UIInput from '@/components/UI/Input/Input'
import UITableResponsive from '@/components/UI/Table/Responsive.vue'
import UICheckbox from '@/components/UI/Input/Checkbox.vue'

const props = defineProps({
  acties: {
    type: Boolean,
    default: false,
  },
  rit_id: {
    type: Number,
    required: true,
  },
})

const api = useApi()
const store = useStore()

const actiesAdmin = computed(() => props.acties && store.getters.hasAdminPermission)
const actiesAllowed = computed(() => actiesAdmin.value && data.value?.is_passagier_lijst)

const form = ref({ naam: '' })

const apiData = computed(() => ({ rit_id: props.rit_id }))
// eslint-disable-next-line no-unused-vars
const { data, getData, cancel, loading } = useGetApi('RIT_PASSAGIERS_LIST', apiData, { watch: true, skipMounted: true })

const originalList = ref([])
const disabled = computed(() => (originalList.value || []).filter(item => item?.is_ingestapt).map(item => item.id))

onMounted(() => {
  getData().then(() => originalList.value = data.value?.list || [])
})

const handleOpslaan = () => {
  if (!actiesAllowed.value || !form.value.naam) return
  api('RIT_PASSAGIERS_CREATE', { rit_id: props.rit_id, ...form.value }).then(() => {
    getData()
  })
}

const handleDelete = (id) => {
  if (!actiesAllowed.value) return
  api('RIT_PASSAGIERS_DELETE', { rit_id: props.rit_id, id }).then(() => {
    getData()
  })
}

const handleIngestapt = (id, is_ingestapt) => {
  api('RIT_PASSAGIERS_INGESTAPT', { rit_id: props.rit_id, id, is_ingestapt}).then(() => {
    data.value.list = data.value.list.map((item) => {
      if (item.id !== id) return item
      if (item.is_ingestapt) return { ...item, is_ingestapt, chauffeur_id: store.getters.currentUserId }
      return { ...item, is_ingestapt }
    })
  }).catch(() => {
    getData()
  })
}

const handleActivatie = (newValue) => {
  api('RIT_PROP_VALUE', { id: props.rit_id, property: 'is_passagier_lijst', value: newValue }).then(() => {
    getData()
  })
}
</script>
