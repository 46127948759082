<!-- ErrorBoundary.vue -->
<template>
  <div v-if="error" class="border border-dashed bg-red-200 border-red-400 p-4 rounded text-red-700 text-lg">
    <h2 class="text-red-900">Error</h2>
    {{ error }}
    {{ errorMessage }}
  </div>
  <div v-else><slot></slot></div>
</template>

<script>
export default {
  data() {
    return {
      error: null,
      errorMessage: '',
    }
  },
  errorCaptured(error, vm, info) {
    this.error = error
    this.errorMessage = info
    return false // Prevents the error from propagating further
  },
}
</script>
