import { ref, computed } from 'vue'
import useWindow from '@/hooks/useWindow'
import { isFunction } from '@vueuse/core'

const contexts = {}

function keepAlive(ws, timeout = 20000) {
  if (ws.readyState != ws.OPEN || !ws.send) {
    return
  }
  ws.send('')
  setTimeout(() => keepAlive(ws, timeout), timeout)
}

const addContext = (endpoint) => {
  contexts[endpoint] = contexts[endpoint] || {
    connected: ref(false),
    connectedOnce: ref(false),
    timeout: null,
    ws: null,
    onConnected() {
      contexts[endpoint].connected.value = true
      contexts[endpoint].connectedOnce.value = true
    },
    onDisconnected() {
      contexts[endpoint].connected.value = false
      clearTimeout(contexts[endpoint].timeout)
      contexts[endpoint].ws = null
    },
  }

  return contexts[endpoint]
}

const getEventListeners = (endpoint, options, reconnect) => {
  const { onOpen, onMessage, onError, onClose, reconnectTime } = options || {}
  const _reconnectTime = parseInt(reconnectTime > 0 ? reconnectTime : 10000, 10)

  const ctx = contexts[endpoint]
  const isOpenOrConnecting = computed(() => ctx.ws && [ctx.ws.OPEN || ctx.ws.CONNECTING].includes(ctx.ws.readyState))

  console.log('WebSocket:', endpoint, 'opening...')

  const onopen = (socket) => {
    keepAlive(socket, 20000)
    console.log('WebSocket:', endpoint, 'opened')
    ctx.onConnected()
    if (isFunction(onOpen)) onOpen(socket)
    if (ctx.ws?.ping) {
      setInterval(() => ctx.ws.ping(), 50000)
      ctx.ws.ping()
    }
  }

  const onclose = (socket) => {
    console.log('WebSocket:', endpoint, 'closed')
    if (isFunction(onClose)) onClose(socket)
    if (isOpenOrConnecting.value && ctx.ws?.close) {
      ctx.ws.close()
    }
    ctx.onDisconnected()
    ctx.timeout = setTimeout(() => reconnect(endpoint, options), _reconnectTime)
  }

  const onmessage = (event) => {
    let message = event || ''
    if (typeof message === 'string') {
      try { message = JSON.parse(message) } catch (e) { /* Nothing */ }
    }
    console.log('WebSocket:', endpoint, '- message:', message)
    if (isFunction(onMessage)) onMessage(message)
    ctx.onConnected()
  }

  const onerror = (event) => {
    console.log('WebSocket:', endpoint, '- error:', event)
    if (isFunction(onError)) onError(event)
    onclose()
  }

  return {
    onopen,
    onclose,
    onmessage,
    onerror,
  }
}

const shouldCreate = async (endpoint) => {
  const ctx = contexts[endpoint]
  const isOpenOrConnecting = computed(() => ctx.ws && [ctx.ws.OPEN || ctx.ws.CONNECTING].includes(ctx.ws.readyState))

  if (ctx.ws && isOpenOrConnecting.value) {
    return false
  } else if (ctx.ws?.close) {
    ctx.ws.close()
    await new Promise(r => setTimeout(r, 100))
  }

  return true
}

// eslint-disable-next-line no-unused-vars
const connectSocketIO = async (endpoint = '', options) => {
  console.log('WebSocket:', endpoint, '- Connection: connectSocketIO')
  const shouldCreateNew = await shouldCreate(endpoint)
  if (!shouldCreateNew) return

  const ctx = contexts[endpoint]

  ctx.ws = window.io(endpoint, {
    withCredentials: true,
  })
  const { onopen, onclose, onmessage } = getEventListeners(endpoint, options, connectSocketIO)
  ctx.ws.on('connection', () => onopen(ctx.ws))
  ctx.ws.on('disconnect', () => onclose(ctx.ws))
  ctx.ws.onAny((eventName, data) => onmessage(ctx.ws, { eventName, data }))
}

const connectPlain = async (endpoint = '', options) => {
  console.log('WebSocket:', endpoint, '- Connection: connectPlain')
  const shouldCreateNew = await shouldCreate(endpoint)
  if (!shouldCreateNew) return

  const ctx = contexts[endpoint]
  ctx.ws = new WebSocket(endpoint)

  const { onopen, onclose, onmessage, onerror } = getEventListeners(endpoint, options, connectPlain)
  ctx.ws.onopen = () => onopen(ctx.ws)
  ctx.ws.onclose = () => onclose(ctx.ws)
  ctx.ws.onmessage = (event) => {
    onmessage.call(ctx.ws, event.data || '{}')
  }
  ctx.ws.onerror = onerror.bind(ctx.ws)
}

export default (endpoint, options) => {
  const defaultResult = { ws: null, connected: ref(false), connectedOnce: ref(false) }

  if (!useWindow()) return defaultResult

  if (!endpoint) {
    console.error('useWebsocket error:', 'requires a endpoint to be passed')
    return defaultResult
  }

  const context = addContext(endpoint)

  // if (options?.plain) {
  connectPlain(endpoint, options)
  // } else {
  // connectSocketIO(endpoint, options)
  // }

  return context
}
