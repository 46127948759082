<template>
  <div v-if="model" class="flex flex-col gap-4">
    <UIInput v-model="model.firstname" label="Voornaam" type="text" class="w-full max-w-sm" placeholder="Voornaam ..." />
    <UIInput v-model="model.lastname" label="Familienaam" type="text" class="w-full max-w-sm" placeholder="Familienaam ..." />
    <!-- <UIInput v-if="!!store.getters.isKantoorPermission" v-model="model.phone" label="Telefoonnummer" type="text" class="w-full max-w-sm" placeholder="Telefoonnummer ..." /> -->
    <UIInput v-model="model.email" label="E-mailadres" type="text" class="w-full max-w-sm" placeholder="E-mailadres ..." />
    <UIInput v-model="model.bestuurderspas" label="Bestuurderspas" type="text" class="w-full max-w-sm" placeholder="Bestuurderspas ..." />
    <UISelectFixed v-if="model.type !== 'master' && model.type !== 'debug'" type="USER_TYPE" class="w-full max-w-sm" v-model="model.type" />
    <UIInput v-if="!store.getters.isKantoorPermission" v-model="model.commisie" label="Commisie %" type="number" class="w-full max-w-sm" placeholder="Commisie ..." />
    <UIInput v-if="!store.getters.isKantoorPermission && model.type === 'onderaannemer'" v-model="model.kbo_nummer" label="KBO Nummer" type="text" class="w-full max-w-sm" placeholder="KBO Nummer ..." />
    <UIInpuntCheckbox v-if="!store.getters.isKantoorPermission" v-model="model.is_cash_toestaan" label="Cash ontvangsten toestaan ?" class="w-full max-w-sm" />
    <UISelectFixed type="LANGUAGE" class="w-full max-w-sm" v-model="model.language" />
    <UISelectWagen v-if="!store.getters.isKantoorPermission" class="w-full max-w-sm" active nullable nullable-label="Geen wagen toekennen" label label-class="text-sm" v-model="model.wagen_id" />
    <div class="flex flex-col gap-2">
      <div>
        <UIInput v-model="model.password" label="Wachtwoord" type="password" class="w-full max-w-sm" placeholder="Wachtwoord ..." />
        <small class="text-xs text-blue-500" v-if="isEdit">Wachtwoordvelden leeg laten om ongewijzigd te houden</small>
      </div>
      <div>
        <UIInput v-model="model.passwordConfirm" label="Wachtwoord bevestigen" type="password" class="w-full max-w-sm" placeholder="Wachtwoord bevestigen ..." />
        <small class="text-xs text-blue-500" v-if="isEdit">Wachtwoordvelden leeg laten om ongewijzigd te houden</small>
      </div>
    </div>
    <button @click="handleSubmit" class="btn success mt-4">Opslaan</button>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'
import { useVModel } from '@vueuse/core'

import UIInput from '@/components/UI/Input/Input'
import UIInpuntCheckbox from '@/components/UI/Input/Checkbox.vue'
import UISelectFixed from '@/components/UI/Select/Fixed'
import UISelectWagen from '@/components/UI/Select/Wagen.vue'
import useApi from '@/hooks/useApi'
import { useStore } from 'vuex'

const props = defineProps({
  callback: Function,
  modelValue: {
    type: Object,
    default() {
      return {
        id: null,
        type: 'chauffeur',
        firstname: '',
        lastname: '',
        commisie: 35,
        is_cash_toestaan: 1,
        bestuurderspas: '',
        kbo_nummer: '',
        phone: '',
        email: '',
        language: 'nl',
        password: '',
        passwordConfirm: '',
        wagen_id: null,
      }
    },
  },
})

const api = useApi()
const store = useStore()

const emit = defineEmits(['update:modelValue'])
const model = useVModel(props, 'modelValue', emit, { deep: true, passive: true })

const isEdit = model.value.id > 0
if (isEdit) {
  model.value.password = ''
  model.value.passwordConfirm = ''
}

const handleSubmit = () => {
  const data = { ...model.value }
  if (data.type !== 'onderaannemer') {
    delete data.kbo_nummer
  }

  api(isEdit ? 'USER_EDIT' : 'USER_CREATE', data).then(() => {
    if (typeof props.callback === 'function') {
      props.callback()
    }
    emit('close', true)
  })
}
</script>
