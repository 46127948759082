<template>
  <div class="input">
    <UIInput v-model="filter" class="mb-2" />
    <RecycleScroller
      :ref="(el) => scroller = el"
      style="max-height: 250px; overflow-y: scroll;"
      :items="filteredItems"
      key-field="page"
      :item-size="32"
      v-slot="{ item }"
    >
      <button @click="handleClick(item.page)" class="text-sm cursor-pointer hover:bg-purple-100 p-1 flex flex-row justify-between w-full">
        <span>Pagina {{ item.page }}</span>
        <i class="fas fa-check mr-2" v-if="item.page === pageModel"></i>
      </button>
    </RecycleScroller>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useVModel } from '@vueuse/core'

import { RecycleScroller } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

import UIInput from '@/components/UI/Input/Input'

const filter = ref('')
const scroller = ref(null)

watch(filter, () => {
  if (scroller.value?.scrollToPosition) {
    scroller.value.scrollToPosition(0)
  }
})

const props = defineProps({
  callback: Function,
  page: Number,
  maxPages: Number,
})

const emit = defineEmits(['update:page', 'close'])
const pageModel = useVModel(props, 'page', emit, { deep: true, passive: true })

const items = computed(() => {
  const result = [{ page: 1 }]
  for (let p = 2; p <= props.maxPages; p += 1) {
    result.push({ page: p })
  }
  return result
})

const handleClick = (newPage) => {
  if (typeof props.callback === 'function') {
    props.callback(newPage)
  }
  pageModel.value = newPage
  emit('close')
}

const filteredItems = computed(() => {
  if (!filter.value) return items.value
  const numberSearch = String(filter.value).toLowerCase().replace(/[^0-9]/gi, '')
  return items.value.filter(el => String(el.page).startsWith(numberSearch))
})
</script>
