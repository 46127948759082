<template>
  <div class="text-sm flex flex-col gap-4 p-2 border-t-4 border-blue-400 bg-blue-300 text-blue-900">
    <h2 class="text-base text-blue-900">Alle adressen zijn nog niet 'geocoded'</h2>
    <p>
      Alle adressen zijn nog niet 'geocoded'.<br>
      Dit gebeurt om de +- 10 minuten door het systeem.<br>
      <span class="text-black">Gelieve nadien nog eens te proberen.</span>
    </p>
    <h3 class="text-sm text-blue-900">Indien deze melding zou blijven bestaan</h3>
    <p>
      Dan is onze IT dienst reeds op de hoogte gesteld via meldingen.<br>
      Mogelijk is dat een adres mislukte om te geocoden.<br>
      Dit zal manueel zo snel mogelijk in orde gebracht worden.
    </p>
  </div>
</template>
