<template>
  <div class="flex flex-col gap-3">
    <UISelectFixed type="EXTERNAL_REVIEWSITES" class="w-full max-w-sm" nullable nullable-label="Geen externe review gegeven" v-model="form.bron" />
    <template v-if="form.bron">
      <UIInputLabel class="bold">Review score</UIInputLabel>
      <div class="flex flex-row justify-center items-center gap-4">
        <button
          v-for="n in 6"
          :key="n"
          class="rounded-full inline-block border h-10 w-10"
          :class="{
            'border-gray-100 bg-gray-50 text-gray-400': form.score === null || form.score < (n - 1) * 2,
            [activeClass]: form.score !== null && form.score >= (n - 1) * 2,
          }"
          @click="form.score = (n - 1) * 2"
        >
          {{ n - 1 }}
        </button>
      </div>
      <UIInput
        v-model="form.link"
        label="Review link"
        type="text"
        class="w-full max-w-sm"
        placeholder="Link naar review ..."
      />
    </template>
    <button :disabled="api.loading.value" class="btn success inline-block mt-4" @click="handleOpslaan">Opslaan</button>
  </div>
</template>

<script setup>
import { defineProps, ref, computed } from 'vue'
import useApi from '@/hooks/useApi'
import UISelectFixed from '@/components/UI/Select/Fixed.vue'
import UIInputLabel from '@/components/UI/Input/Label'
import UIInput from '@/components/UI/Input/Input'
import { useVModel } from '@vueuse/core'

const props = defineProps({
  rit: {
    type: Object,
    required: true,
    default() {
      return {
        id: null,
        is_online_recensie: false,
      }
    },
  },
  callback: Function,
})

const api = useApi()
const emit = defineEmits(['close'])

const vModelRit = useVModel(props, 'rit', emit, { deep: true, passive: true })

const form = ref({
  rit_id: props.rit.id,
  score: 0,
  bron: null,
  link: null,
})

api('ENQUETE_DATA', { rit_id: props.rit.id, external: true }).then(({ data }) => {
  const enquete = data || {}
  if (!enquete.bron || enquete.bron === 'WEBSITE') return
  if (enquete.bron) form.value.bron = enquete.bron
  if (enquete.score_heen) form.value.score = enquete.score_heen
  if (enquete.opmerking_heen) form.value.link = enquete.opmerking_heen
})

const enqueteForm = computed(() => ({
  rit_id: form.value.rit_id,
  bron: form.value.bron,
  score_heen: form.value.score,
  opmerking_heen: props.rit?.is_retour ? form.value.link : null,
  score_retour: props.rit?.is_retour ? form.value.score : 0,
  opmerking_retour: null,
}))

const activeClass = computed(() => {
  if (form.value.score <= 4) return 'border-red-500 bg-red-500 text-white'
  if (form.value.score <= 6) return 'border-orange-500 bg-orange-500 text-white'
  if (form.value.score <= 8) return 'border-yellow-500 bg-yellow-400 text-white'
  return 'border-green-400 bg-green-400 text-white'
})

const handleOpslaan = async () => {
  await api(enqueteForm.value.bron ? 'ENQUETE_CREATE' : 'ENQUETE_DELETE', { ...enqueteForm.value, external: true })
  await api('RIT_PROP_VALUE', {
    id: form.value.rit_id,
    property: 'is_online_recensie',
    value: !!enqueteForm.value.bron,
  })
  if (typeof props.callback === 'function') {
    props.callback()
  }
  vModelRit.value.is_online_recensie = !!enqueteForm.value.bron
  emit('close')
}
</script>
