<template>
  <div v-if="modelValue?.doc_number && modelValue?.type">
    <div class="card text-base">
      <h2>
        <span>Mail verzenden &mdash; Document {{ modelValue.doc_number }} </span>
        <button :disabled="disableTestMail" @click="sendTestMail" class="btn small warn"><i class="fas fa-envelope"></i> Testmail sturen</button>
      </h2>
      <div class="grid lg:grid-cols-2 gap-8">
        <div class="flex flex-col gap-2 items-start w-full max-w-sm">
          <UISelectFixed :type="selectFixedType" v-model="form.sjabloon" />
          <UIInput v-model="form.voornaam" label="Voornaam" type="text" placeholder="Voornaam ..." />
          <UIInput v-model="form.email" label="E-mailadres" type="text" placeholder="E-mailadres ..." />
          <div class="flex gap-2 items-center justify-between w-full">
            <button :disabled="form.type !== 'INVOICE' || form.sjabloon === 'FACTUUR_ADVOCAAT'" @click="() => handleSubmit({ isManueel: false })" class="btn success mt-4 px-4">
              <i class="fas fa-envelope"></i> Verzenden
            </button>
            <button :disabled="form.type !== 'INVOICE'" @click="() => handleSubmit({ isManueel: true })" class="btn indigo mt-4 px-4">
              <i class="fas fa-envelope"></i> Manueel verzonden
            </button>
          </div>
          <span v-if="form.type !== 'INVOICE'" class="text-red-500 font-bold">TODO: Mails enkel voor facturen momenteel.</span>
        </div>
        <div class="flex flex-col gap-2">
          <div class="card gray flex flex-col gap-1">
            <p><b>Document:</b> {{ modelValue.doc_number }}</p>
            <p><b>C-ID:</b> {{ modelValue.client_id }}</p>
            <p><b>Thema:</b> {{ modelValue.bedrijf_thema }}</p>
            <FacturatieLijn column label="Facturatiegegevens" class="py-0" :facturatie="modelValue" />
          </div>
          <div class="card gray" :key="contacts?.length">
            <b class="block">Contacten</b>
            <small v-if="!contacts?.length">Nog geen contacten opgeslagen.</small>
            <select v-else v-model="contactIndex" :class="{ 'bg-white': contactIndex > -1, 'bg-gray-100': contactIndex === -1 }">
              <option disabled :value="-1">Maak een keuze ...</option>
              <option v-for="(contact, index) in contacts" :value="index" :key="[index, contact.voornaam, contact.email].join('-')">
                {{ contact.voornaam }} - {{ contact.email }} <span v-if="contact.is_default">(Standaard)</span>
              </option>
            </select>
            <div v-if="contacts?.length" class="flex flex-row items-start gap-2 mt-2">
              <button :disabled="contactIndex === -1" @click="() => contactInvullen()" class="btn success">Invullen</button>
              <button :disabled="contactIndex === -1" @click="() => contactStandaard()" class="btn info">Als standaard instellen</button>
              <button :disabled="contactIndex === -1" @click="() => contactVerwijderen()" class="btn error">Verwijderen</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-8">
      <h2>Historiek mails</h2>
      <TableMails hide-legend :doc_number="modelValue.doc_number" :subject="mailSjablonen" style="font-size: 0.8rem;"></TableMails>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, onMounted, ref } from 'vue'

import UISelectFixed from '@/components/UI/Select/Fixed.vue'
import UIInput from '@/components/UI/Input/Input'
import useApi from '@/hooks/useApi'
import TableMails from '@/components/Table/Mails/Index.vue'
import { MAIL_SJABLONEN_FACTUUR, MAIL_SJABLONEN_CREDIT_NOTE } from '@/constants/OPTIONS'
import FacturatieLijn from '@/components/Table/Planning/FacturatieLijn.vue'
// import UIInputCheckbox from '@/components/UI/Input/Checkbox'

const props = defineProps({
  callback: Function,
  modelValue: {
    type: Object,
    default() {
      return {
        doc_number: '',
      }
    },
  },
})

const selectFixedType = computed(() => {
  if (props.modelValue?.type === 'CREDIT_NOTE') return 'MAIL_SJABLONEN_CREDIT_NOTE'
  return 'MAIL_SJABLONEN_FACTUUR'
})

const mailSjablonen = computed(() => {
  if (props.modelValue?.type === 'CREDIT_NOTE') return MAIL_SJABLONEN_CREDIT_NOTE().map(el => el.value)
  return MAIL_SJABLONEN_FACTUUR().map(el => el.value)
})

const emit = defineEmits(['close'])
const api = useApi()

const form = ref({
  journal_id: props.modelValue?.journal_id,
  doc_number: props.modelValue?.doc_number,
  type: props.modelValue?.type,
  sjabloon: mailSjablonen.value?.length ? mailSjablonen.value[0] : 'FACTUUR',
  email: '',
  voornaam: '',
})

const contacts = ref([])
const contactIndex = ref(-1)

const selectedContact = computed(() => {
  if (contactIndex.value === -1) return null
  return contacts.value[contactIndex.value] || {}
})

const loadContacts = async () => {
  return api.get('FINANCIAL_CONTACTS', { client_id: props.modelValue?.client_id }).then(({ data }) => {
    const list = (data.list || [])
    contacts.value = list
    return list
  })
}

const contactInvullen = () => {
  form.value.email = selectedContact.value?.email || ''
  form.value.voornaam = selectedContact.value?.voornaam || ''
}

const contactStandaard = async () => {
  if (contactIndex === -1) return
  return api.patch('FINANCIAL_CONTACTS_STANDAARD', { client_id: props.modelValue?.client_id, id: selectedContact.value?.id }).then(() => {
    loadContacts()
  })
}

const contactVerwijderen = async () => {
  if (contactIndex === -1) return
  await api.delete('FINANCIAL_CONTACTS_DELETE', { client_id: props.modelValue?.client_id, id: selectedContact.value?.id }).then(() => {
    loadContacts()
  })
}

onMounted(async () => {
  const contacts = await loadContacts()

  if (contacts?.length) {
    const index = contacts.findIndex(el => el.is_default)
    const noDefaultYet = !!(index === -1)
    contactIndex.value = Number(noDefaultYet ? 0 : index)
    if (noDefaultYet) await contactStandaard()
    contactInvullen()
  }
})

const handleSubmit = ({ isManueel }) => {
  if (form.value.type !== 'INVOICE') {
    alert('Er liep iets verkeerd. (E-9000)')
    return
  }

  const data = {
    ...form.value,
    isManueel: !!isManueel,
  }

  api('FINANCIAL_MAILEN', data).then(() => {
    if (typeof props.callback === 'function') {
      props.callback()
    }
    emit('close', true)
  })
}

const disableTestMail = ref(false)
const sendTestMail = () => {
  disableTestMail.value = true

  const data = {
    ...form.value,
    isTestmail: true,
    email: 'info+testmail@luchthavenvervoer-kevin.be',
    voornaam: 'Kevin',
  }
  api('FINANCIAL_MAILEN', data).then(() => {
    setTimeout(() => {
      disableTestMail.value = false
    }, 8000)
  }).catch(() => {
    disableTestMail.value = false
  })
}

</script>
