export const formatDecimals = (value, decimals = 2) => {
  let n = Number(value)
  if (isNaN(n)) n = 0
  if (decimals <= 0) {
    return String(Math.round(n))
  }
  const coeff = Math.pow(10, decimals)
  return (Math.round(n * coeff) / coeff).toFixed(decimals)
}

export const formatInteger = (value) => String(Math.round(value))

export const formatPrice = (value) => formatDecimals(value, 2)
export const formatDecimalDefault = (value) => formatDecimals(value, 2)
export const formatEuro = (value) => `\u20AC\xa0${formatPrice(value, 2)}`
export const formatDollar = (value) => `\u0024\xa0${formatPrice(value, 2)}`
