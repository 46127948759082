<template>
  <div class="flex flex-col gap-3">
    <small v-if="form.nordigen_uuid" class="text-sm italic -mt-2 pb-2"><b>Nordigen UUID:</b> {{ form.nordigen_uuid }}</small>
    <UIInputDatePicker required label="Datum" class="text-left" v-model:date="form.date" />
    <UIInput required v-model="form.bedrag" label="Bedrag" type="number" class="w-full max-w-sm" placeholder="Bedrag ..." />
    <UISelectDataOptions required label="Type" options-table="options_kosten_types" v-model="form.type" />
    <UISelectFixed required label="Soort" type="KOSTEN_SOORT" v-model="form.soort" placeholder="Selecteer ..." />
    <UISelectDataOptions all nullable nullable-label="Geen locatie" label="Locatie" options-table="options_kosten_locaties" v-model="form.locatie" />
    <UISelectWagen nullable nullable-label="Geen wagen" class="w-full max-w-sm" :active="store.getters.hasKantoorPermission ? undefined : true" label v-model="form.wagen_id" />
    <UISelectChauffeur nullable nullable-label="Geen chauffeur" v-if="store.getters.hasKantoorPermission" class="w-full max-w-sm" active label v-model="form.user_id" />
    <UIInput textarea v-model="form.opmerking" label="Opmerking" rows="4" class="w-full max-w-sm" placeholder="Eventuele opmerking ..." />

    <button class="btn success inline-block" @click="handleOpslaan">Opslaan</button>
  </div>
</template>


<script setup>
import { defineProps, ref  } from 'vue'
import UIInput from '@/components/UI/Input/Input'
import UIInputDatePicker from '@/components/UI/Input/DatePicker.vue'
import UISelectFixed from '@/components/UI/Select/Fixed.vue'
import UISelectDataOptions from '@/components/UI/Select/SelectDataOptions.vue'
import UISelectChauffeur from '@/components/UI/Select/Chauffeur.vue'
import UISelectWagen from '@/components/UI/Select/Wagen.vue'

import { dateString, isDate } from '@/functions/formatDate'
import { useStore } from 'vuex'
import useApi from '@/hooks/useApi'
import { useVModel } from '@vueuse/core'
import clone from 'just-clone'

const props = defineProps({
  callback: {
    type: Function,
  },
  modelValue: {
    type: Object,
    default() {
      return {
        date: null,
        bedrag: null,
        type: null,
        soort: 'VARIABLE',
        locatie: null,
        wagen_id: null,
        user_id: null,
        nordigen_uuid: null,
        opmerking: null,
      }
    },
  },
})

const store = useStore()
const emit = defineEmits(['close', 'update:modelValue'])
const record = useVModel(props, 'modelValue', emit, { deep: true, passive: true })

const form = ref({
  date: dateString(record.value && isDate(record.value.date) ? record.value.date : new Date(Date.now())),
  bedrag: Number(record.value?.bedrag > 0 ? record.value.bedrag : 0),
  type: record.value?.type || null,
  soort: record.value?.soort || null,
  locatie: record.value?.locatie || null,
  wagen_id: record.value?.wagen_id > 0 ? record.value.wagen_id : null,
  user_id: null,
  nordigen_uuid: record.value?.nordigen_uuid || null,
  opmerking: record.value?.opmerking || null,
})

if (!store.getters.hasKantoorPermission) {
  form.value.user_id = store.getters.currentUserId
} else if (record.value?.user_id) {
  form.value.user_id = record.value?.user_id
}

const api = useApi()

const handleOpslaan = () => {
  const isEdit = record.value?.id > 0
  const endpoint = (isEdit > 0 ? 'KOST_BEWERKEN' : 'KOST_CREATE')

  const data = clone(form.value)

  if (isEdit) data.id = record.value.id
  if (!store.getters.hasKantoorPermission) {
    data.user_id = store.getters.currentUserId
  }

  api(endpoint, data).then(() => {
    record.value.date = data.date
    record.value.bedrag = data.bedrag
    record.value.type = data.type
    record.value.soort = data.soort
    record.value.locatie = data.locatie
    record.value.wagen_id = data.wagen_id || null
    record.value.user_id = data.user_id || null
    record.value.opmerking = data.opmerking || null

    if (typeof props.callback === 'function') {
      props.callback()
    }

    emit('close', true)
  })
}
</script>
