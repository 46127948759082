import { computed, onMounted, ref, watch, unref } from 'vue'
import clone from 'just-clone'

import API_ENDPOINTS from '@/constants/API_ENDPOINTS'
import useApi from '@/hooks/useApi'

export default (_endpoint, apiData = {}, options = {}) => {
  const { wait, watch: shouldWatch, skipMounted, onData } = options
  const api = useApi()

  let data = ref(null)
  let loading = ref(false)

  const params = computed(() => clone(unref(apiData || {})))

  const getData = async () => {
    if (!_endpoint) {
      console.error(new Error('Function useGetApi called without a endpoint'))
      return {}
    }
    let endpoint = API_ENDPOINTS[_endpoint]?.endpoint || _endpoint

    api.cancel()

    loading.value = true
    const result = await api.get(endpoint, { params: params.value })
    if (result.data) {
      if (typeof onData === 'function') {
        onData(clone(result.data))
      }
      data.value = result.data
    }

    if (wait > 0) {
      setTimeout(() => { loading.value = false }, wait)
    } else {
      loading.value = false
    }

    return result
  }

  const reset = () => {
    api.cancel()
    data.value = null
    loading.value = false
  }

  onMounted(() => {
    if (!skipMounted) getData()
    if (shouldWatch) watch(params, getData)
  })

  return { data, loading, reset, getData, cancel: api.cancel }
}
