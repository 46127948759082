<template>
  <div class="flex flex-col gap-2">
    <UISelectChauffeur v-if="store.getters.hasKantoorPermission && !beschikbaarheid.user_id" class="w-full max-w-sm" active label v-model="form.user_id" />
    <UISelectFixed v-if="isAfwezig && beschikbaarheid.reason !== 'AANGEVRAAGD'" type="AFWEZIGHEID_REASON" class="w-full max-w-sm" label="Reden" v-model="form.reason" />
    <div>
      <UIInputDatePicker required label="Datum en tijd" class="text-left" timepicker :min-date="minDate" v-model:date="form.start_datum" v-model:time="form.start_tijd" />
      <small class="-mt-2 text-sm text-gray-500 italic">Tijd zal worden afgerond naar eerst vorig kwartier</small>
    </div>
    <div>
      <UIInputDatePicker required label="Datum en tijd" class="text-left" timepicker :min-date="minDate" v-model:date="form.einde_datum" v-model:time="form.einde_tijd" />
      <small class="-mt-2 text-sm text-gray-500 italic">Tijd zal worden afgerond naar eerst volgend kwartier</small>
    </div>
    <UIInput textarea v-model="form.opmerking" label="Opmerking" rows="4" class="w-full max-w-sm" placeholder="Eventuele opmerking ..." />
    <button class="btn success inline-block" @click="handleOpslaan">Opslaan</button>
  </div>
</template>

<script setup>
import { defineProps, ref, watch } from 'vue'
import UIInput from '@/components/UI/Input/Input'
import UIInputDatePicker from '@/components/UI/Input/DatePicker.vue'
import UISelectFixed from '@/components/UI/Select/Fixed'
import UISelectChauffeur from '@/components/UI/Select/Chauffeur.vue'
import { dateString, isDate, timeString, dateAndTimeToUTC } from '@/functions/formatDate'
import { useStore } from 'vuex'
import useApi from '@/hooks/useApi'
import { useVModel } from '@vueuse/core'
import clone from 'just-clone'

const props = defineProps({
  tabel: String,
  minDate: [Date, String, Number],
  callback: {
    type: Function,
  },
  modelValue: {
    type: Object,
    default() {
      return {
        user_id: null,
        reason: 'VAKANTIE',
        opmerking: null,
        start: null,
        einde: null,
      }
    },
  },
})

const store = useStore()
const emit = defineEmits(['close', 'update:modelValue'])
const beschikbaarheid = useVModel(props, 'modelValue', emit, { deep: true, passive: true })

const isAfwezig = props.tabel === 'afwezigheden'
const isAanwezig = props.tabel === 'aanwezigheden'

const defaultExtraMs = 86400000 * (isAfwezig ? 7 : 1)

const form = ref({
  user_id: beschikbaarheid.value?.user_id || null,
  reason: isAanwezig ? 'AANGEVRAAGD' : beschikbaarheid.value?.reason || 'VAKANTIE',
  start_datum: dateString(beschikbaarheid.value && isDate(beschikbaarheid.value.start) ? beschikbaarheid.value.start : new Date(Date.now() + defaultExtraMs)),
  start_tijd: timeString(beschikbaarheid.value && isDate(beschikbaarheid.value.start) ? beschikbaarheid.value.start : '00:00'),
  einde_datum: dateString(beschikbaarheid.value && isDate(beschikbaarheid.value.einde) ? beschikbaarheid.value.einde : new Date(Date.now() + defaultExtraMs)),
  einde_tijd: timeString(beschikbaarheid.value && isDate(beschikbaarheid.value.einde) ? beschikbaarheid.value.einde : '23:59'),
  opmerking: beschikbaarheid.value?.opmerking || null,
})

const tijdTransform = (roundingFn, propName, defaultMustBe = '00:00') => {
  let mustBe = defaultMustBe
  const filledValue = form.value[propName]
  if (filledValue === defaultMustBe) return

  if (filledValue) {
    const parts = String(filledValue).split(':')
    const hours = Number(parts[0])
    const minutes = Number(parts.slice(-1)[0])
    const newMinutes = (roundingFn(minutes/15) * 15) % 60
    mustBe = `${hours}:${newMinutes}`
  }

  if (filledValue === mustBe) return
  form.value[propName] = mustBe
}

watch(() => form.value.start_tijd, () => tijdTransform(Math.floor, 'start_tijd', '00:00'))
watch(() => form.value.einde_tijd, () => tijdTransform(Math.ceil, 'einde_tijd', '23:59'))

if (!store.getters.hasKantoorPermission) {
  form.value.user_id = store.getters.currentUserId
}

watch(() => form.value.start_datum, (start_datum) => {
  if (form.value.einde_datum < start_datum) {
    form.value.einde_datum = String(start_datum)
    form.value.einde_tijd = '23:59'
  }
})

const api = useApi()
const handleOpslaan = () => {
  const isEdit = beschikbaarheid.value?.id > 0
  const endpoint = (isEdit > 0 ? 'BESCHIKBAARHEID_BEWERKEN' : 'BESCHIKBAARHEID_CREATE')

  const data = clone(form.value)
  data.start = dateAndTimeToUTC(data.start_datum, data.start_tijd)
  data.einde = dateAndTimeToUTC(data.einde_datum, data.einde_tijd)

  if (isEdit) data.id = beschikbaarheid.value.id

  if (!store.getters.hasKantoorPermission) {
    data.user_id = store.getters.currentUserId
  }

  data.tabel = props.tabel

  api(endpoint, data).then(() => {
    beschikbaarheid.value.user_id = data.user_id || null
    beschikbaarheid.value.reason = data.reason || null
    beschikbaarheid.value.start = data.start
    beschikbaarheid.value.einde = data.einde
    beschikbaarheid.value.opmerking = data.opmerking || null

    if (typeof props.callback === 'function') {
      props.callback()
    }
    emit('close', true)
  })
}
</script>
