<template>
  <UIModal :title="isOpen ? DIALOG_TITLES[modal.type] : ''" v-model="isOpen" close v-bind="DialogOptions">
    <div class="modal" v-show="isOpen">
      <pre v-if="!DialogComponent">Unknown modal type: {{ { modal } }}</pre>
      <component v-else :is="DialogComponent" v-bind="DialogComponentProps" @close="isOpen = false"></component>
    </div>
  </UIModal>
</template>

<script setup>
import { inject, computed } from 'vue'
import UIModal from '@/components/UI/Modal'
import { DIALOG_COMPONENTS, DIALOG_TITLES, DIALOG_PROPS } from '@/constants/DIALOGS'

const modal = inject('modal')
const isOpen = computed({
  get: () => {
    return modal?.value?.type && !!DIALOG_COMPONENTS[modal.value.type]
  },
  set: val => {
    if (!val) modal.value = null
  },
})

const DialogComponent = computed(() => (isOpen.value ? DIALOG_COMPONENTS[modal.value.type] : null))

const DialogComponentProps = computed(() => ({
  ...(isOpen.value && DialogComponent.value && modal.value.props ? modal.value.props : {}),
  ...(DIALOG_PROPS[`${modal?.value?.type}`] || {}),
}))

const DialogOptions = computed(() => isOpen.value && DialogComponent.value && modal.value.options ? modal.value.options : {})
</script>

<style scoped lang="scss">
.modal {
  box-sizing: border-box;
  background-color: #fff;
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 40px;
  padding-left: 10px;
  padding-right: 10px;
  margin: auto;

  @media all and (max-width: 768px) {
    padding: 30px;
  }

  @media all and (max-width: 520px) {
    padding: 20px;
  }
}
</style>
