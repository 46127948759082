<template>
  <div v-if="!store.getters.hasKantoorPermission">
    <small class="text-red-500 font-bold">Geen rechten om betalingen aan te passen</small>
  </div>
  <div v-else class="flex flex-col gap-3">
    <UISelectFixed type="BETAALMETHODE_FACTUUR" class="w-full max-w-sm" label="Betaalmethode" v-model="betaling.methode" />
    <UIInputDatePicker label="Betaal datum" class="text-left" v-model:date="betaling.date" />
    <UIInput v-model="betaling.bedrag" label="Bedrag betaald" type="number" class="w-full max-w-sm" placeholder="Bedrag terugbetaling ..." />

    <button class="btn success inline-block mt-2" @click="handleOpslaan">Opslaan</button>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue'
import { useStore } from 'vuex'
import { useVModel } from '@vueuse/core'

import useApi from '@/hooks/useApi'
import { dateString, isDate } from '@/functions/formatDate'

import UIInput from '@/components/UI/Input/Input'
import UIInputDatePicker from '@/components/UI/Input/DatePicker.vue'
import UISelectFixed from '@/components/UI/Select/Fixed'

const props = defineProps({
  callback: {
    type: Function,
    required: true,
  },
  modelValue: {
    type: Object,
    default() {
      return {
        id: null,
        journal_id: null,
        doc_number: null,
        type: null,
        date: null,
        bedrag: null,
        methode: null,
      }
    },
  },
})

const store = useStore()
const emit = defineEmits(['close'])
const vModel = useVModel(props, 'modelValue', emit, { deep: true, passive: true })

const betaling = ref({
  id: vModel.value.id,
  journal_id: vModel.value.journal_id,
  doc_number: vModel.value.doc_number,
  type: vModel.value.type,
  date: dateString(vModel.value.date && isDate(vModel.value.date) ? new Date(vModel.value.date) : new Date()),
  bedrag: Math.max(0, vModel.value.bedrag),
  methode: vModel.value.methode || 'CASH',
})

const api = useApi()
const handleOpslaan = () => {
  api('FINANCIAL_BETALING_CREATE', betaling).then(() => {
    Object.entries(betaling.value).forEach(([key, value]) => {
      vModel.value[key] = value
    })
    emit('close')
    if (typeof props.callback === 'function') {
      props.callback()
    }
  })
}
</script>
