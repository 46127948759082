import { FINANCIAL_JOURNALS } from '@/constants/OPTIONS'
import { resetTime } from './formatDate'

export const capitalize = string => {
  if (!string) return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const uppercase = string => {
  return (string || '').toUpperCase()
}

export const lowercase = string => {
  return (string || '').toLowerCase()
}

export const capitalizeWords = string => {
  return string.replace(/(?:^|\s)\S/g, (a) => {
    return a.toUpperCase()
  })
}

export const formatHeader = string => {
  return capitalize(string).replaceAll('_', ' ')
}

export const formatName = item => {
  if (!item) return ''
  if (typeof item === 'string') return item
  const firstname = item.voornaam || item.firstname || item.naam || item.name || ''
  const lastname = item.familienaam || item.lastname || ''
  return `${firstname} ${lastname} ${item.label}`.trim()
}

export const formatDocNumber = (s = '', journal_id = 1) => {
  const journal = FINANCIAL_JOURNALS.find(j => j.value == journal_id)

  let doc_number_text = ''
  if (journal?.mask) {
    const docStr = String(s || '').toUpperCase().toString().padStart(journal.mask.replace(/[^#]/gi, '').length, '0')
    let index = 0
    for (let i = 0; i < journal.mask.length; i++) {
      if (journal.mask[i] === '#') {
        doc_number_text += docStr[index] || ''
        index++
      } else {
        doc_number_text += journal.mask[i]
      }
    }
    for (let i = index; i < docStr.length; i++) {
      doc_number_text += docStr[i]
    }
  }

  if (journal.prefix) return `${journal.prefix}-${doc_number_text}`

  return doc_number_text
}

export const formatDocStatus = (_amountOpen, _date) => {
  if (_amountOpen > 0) {
    if (resetTime(new Date(_date)).getTime() <= resetTime(new Date()).getTime()) {
      return { label: 'ACHTERSTALLIG', class: 'text-red-500' }
    }

    return { label: 'OPEN', class: 'text-gray-400' }
  }

  return { label: 'GESLOTEN', class: 'text-green-500' }
}
