<template>
  <div class="flex flex-col gap-4 mt-8 -mb-8">
    <div class="flex flex-row gap-6 justify-center">
      <button class="btn rounded w-10 bg-dashboard-dark text-white" :disabled="fontSize <= 80" @click="add(-5)">-</button>
      <i class="fas fa-text-height"></i>
      <button class="btn rounded w-10 bg-dashboard-dark text-white" :disabled="fontSize >= 120" @click="add(5)">+</button>
    </div>
    <div class="text-center font-bold">
      {{ fontSize }}%
    </div>
  </div>
</template>

<script setup>
import useFontSizeGlobal from '@/hooks/useFontSizeGlobal'

const fontSize = useFontSizeGlobal()
const add = (n) => fontSize.value += n
</script>
