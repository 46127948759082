import useWindow from '@/hooks/useWindow'

export default () => {
  if (useWindow() && window.navigator && navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations()
      .then((registrations) => {
        for (let registration of registrations) {
          registration.unregister()
        }
      })
  }
}
