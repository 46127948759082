<template>
  <UISelectModalNullable
    v-bind="$attrs"
    v-model="innerValue"
    :label="label === false ? '' : (typeof label === 'string' ? label : 'Chauffeur')"
    :options="options"
    :formatLabel="formatLabelChauffeurs"
    :placeholder="placeholder"
    search-placeholder="Chauffeur zoeken..."
  />
</template>

<script setup>
import { ref, computed, watch, defineProps, defineEmits, inject, onMounted } from 'vue'
import { useStore } from 'vuex'
import clone from 'just-clone'

import UISelectModalNullable from '@/components/UI/Select/SelectModalNullable.vue'
import useRouteQueryInt from '@/hooks/useRouteQueryInt'

const props = defineProps({
  label: [Boolean, String],
  formatLabel: Function,
  useQuery: Boolean,
  skipMounted: Boolean,
  prefixItems: Array,
  suffixItems: Array,
  filterChauffeurs: Function,
  active: {
    type: Boolean,
    default: undefined,
  },
  placeholder: {
    type: String,
    default: 'Selecteer een chauffeur',
  },
  modelValue: {
    type: [String, Number],
    default: null,
  },
})

const store = useStore()
const emit = defineEmits(['update:modelValue'])
const formatLabelChauffeurs = inject('formatLabelChauffeurs', undefined) || props.formatLabel

const options = computed(() => {
  const id = Number(props.modelValue || -1)
  let array = clone(store.state.chauffeurs || [])
  if (typeof props.active !== 'undefined') {
    // Enkel actieve of reeds geselecteerde!
    array = array.filter(item => item.is_active == props.active || item.value == id || item.id == id)
  }
  if (typeof props.filterChauffeurs === 'function') {
    array = array.filter(props.filterChauffeurs)
  }
  if (props.prefixItems?.length) {
    array = [].concat(props.prefixItems, array)
  }
  if (props.suffixItems?.length) {
    array = [].concat(array, props.suffixItems)
  }
  return clone(array)
})

const safeModelValue = () => {
  if (props.modelValue > 0) innerValue.value = Number(props.modelValue)
  if (innerValue.value === '' || isNaN(innerValue.value)) innerValue.value = null
}

const innerValue = props.useQuery ? useRouteQueryInt('chauffeur', null) : ref(props.modelValue || null)
if (!props.useQuery) {
  onMounted(() => {
    watch(() => props.modelValue, () => {
      innerValue.value = props.modelValue || null
    })
  })
}
safeModelValue()

const emitValue = (val) => emit('update:modelValue', val)
watch(() => innerValue.value, (val, oldval) => {
  if (val === oldval) return
  emitValue(val)
})
if (!props.skipMounted) emitValue(innerValue.value)
</script>
