<template>
  <div v-if="vModel" class="flex flex-col gap-4">
    <div class="flex flex-row items-center justify-center gap-2 -mt-2" v-if="countFilled > -1 && countFilled < 5">
      <div class="bg-orange-100 border-2 border-orange-200 h-4 w-4 rounded-sm">&nbsp;</div>
      <small class="font-bold italic text-gray-500 text-sm">Gelieve oranje veld nu als volgende in te vullen</small>
    </div>

    <div v-if="editAll">
      <UISelectChauffeur v-model="form.user_id" class="max-w-xs" label="Chauffeur" />
    </div>

    <div :class="{ 'current--step': countFilled > -1 && countFilled === 0 }">
      <UISelectWagen class="w-full max-w-sm" :disabled="!editAll && countFilled >= 1" active label="Wagen waarmee je rijdt" v-model="form.wagen_id" />
      <small v-if="!editAll && countFilled >= 1" class="h-3 block italic text-xs text-blue-500">Staat vast opgeslagen!</small>
    </div>

    <div :class="{ 'current--step': countFilled > -1 && countFilled === 0 }">
      <UIInputDatePicker :disabled="api.loading.value" label="Start van je shift" class="text-left z-90" timepicker v-model:date="form.start.date" v-model:time="form.start.time" />
    </div>

    <div :class="{ 'current--step': countFilled > -1 && countFilled === 1 }">
      <UIInputDatePicker v-if="shift.start || editAll" :disabled="api.loading.value" :label="labelAangekomen" class="text-left z-90" timepicker v-model:date="form.aangekomen.date" v-model:time="form.aangekomen.time" />
      <div v-else class="-mb-1">
        <UIInputLabel>{{labelAangekomen}}</UIInputLabel>
        <p class="-mt-1 text-sm text-yellow-500">Gelieve de vorige stap/tijd eerst in te vullen.</p>
      </div>
    </div>

    <div :class="{ 'current--step': countFilled > -1 && countFilled === 2 }" class="-mb-2 mt-2">
      <UIInputCheckbox v-if="shift.aangekomen || editAll" :disabled="countFilled >= 3 || api.loading.value" :label="editAll ? `'NO SHOW' / Niet gereden` : `Klant is 'NO SHOW' ?`" class="text-left z-90" timepicker v-model="form.is_no_show" />
      <!-- <p class="text-sm -mt-1 text-blue-900" v-if="countFilled > -1 && countFilled === 2">Enkel aanduiden als de klant niet komt opdagen !</p>
      <p class="text-sm -mt-1 text-blue-900" v-if="countFilled > -1 && countFilled === 2">Of indien je deze rit niet verder dient uit te voeren !</p> -->
    </div>

    <div v-if="form.is_no_show" :class="{ 'current--step': countFilled > -1 && countFilled === 2 }">
      <UIInputDatePicker v-if="shift.aangekomen || editAll" :disabled="countFilled >= 3 || api.loading.value" label="Opnieuw vertrokken met wagen" class="text-left z-90" timepicker v-model:date="form.klant_ingestapt.date" v-model:time="form.klant_ingestapt.time" />
      <div v-else class="-mb-1">
        <UIInputLabel>Opnieuw vertrokken met wagen</UIInputLabel>
        <p class="-mt-1 text-sm text-yellow-500">Gelieve de vorige stap/tijd eerst in te vullen.</p>
      </div>
      <small v-if="!editAll && countFilled >= 3" class="h-3 block italic text-xs text-blue-500">Staat vast opgeslagen!</small>
    </div>
    <div v-else :class="{ 'current--step': countFilled > -1 && countFilled === 2 }">
      <UIInputDatePicker v-if="shift.aangekomen || editAll" :disabled="countFilled >= 3 || api.loading.value" label="Klant ingestapt in wagen" class="text-left z-90" timepicker v-model:date="form.klant_ingestapt.date" v-model:time="form.klant_ingestapt.time" />
      <div v-else class="-mb-1">
        <UIInputLabel>Klant ingestapt in wagen</UIInputLabel>
        <p class="-mt-1 text-sm text-yellow-500">Gelieve de vorige stap/tijd eerst in te vullen.</p>
      </div>
      <small v-if="!editAll && countFilled >= 3" class="h-3 block italic text-xs text-blue-500">Staat vast opgeslagen!</small>
    </div>

    <div v-if="!form.is_no_show" :class="{ 'current--step': countFilled > -1 && countFilled === 3 }">
      <UIInputDatePicker v-if="shift.klant_ingestapt || editAll" :disabled="countFilled >= 4 || api.loading.value" label="Klant afgezet op eindbestemming" class="text-left z-90" timepicker v-model:date="form.klant_afgezet.date" v-model:time="form.klant_afgezet.time" />
      <div v-else class="-mb-1">
        <UIInputLabel>Klant afgezet op eindbestemming</UIInputLabel>
        <p class="-mt-1 text-sm text-yellow-500">Gelieve de vorige stap/tijd eerst in te vullen.</p>
      </div>
      <small v-if="!editAll && countFilled >= 4" class="h-3 block italic text-xs text-blue-500">Staat vast opgeslagen!</small>
    </div>

    <div :class="{ 'current--step': countFilled > -1 && countFilled === 4 }">
      <UIInputDatePicker v-if="shift.klant_afgezet || editAll" :disabled="api.loading.value" label="Einde van je shift" class="text-left z-90" timepicker v-model:date="form.einde.date" v-model:time="form.einde.time" />
      <div v-else class="-mb-1">
        <UIInputLabel>Einde van je shift</UIInputLabel>
        <p class="-mt-1 text-sm text-yellow-500">Gelieve de vorige stap/tijd eerst in te vullen.</p>
      </div>
    </div>

    <div class="py-2">
      <UISelectFixed :disabled="api.loading.value || !shift.klant_ingestapt" label="Wachttijd (Indien van toepassing)" class="text-left z-90" type="WACHTTIJD" v-model="form.wachttijd" />
    </div>

    <div class="inline-flex flex-col gap-1 mt-2">
      <small>Belgische tijd invullen!</small>
      <button class="btn success inline-block" @click="handleOpslaan">Opslaan</button>
    </div>

    <small class="text-xs text-gray-400" v-if="(shift.shift_id || shift.id) > 0">Shift #{{ shift.shift_id || shift.id }}</small>
  </div>
</template>

<script setup>
import clone from 'just-clone'
import { defineProps, defineEmits, ref, computed } from 'vue'
import { useVModel } from '@vueuse/core'
import UIInputCheckbox from '@/components/UI/Input/Checkbox'
import UIInputDatePicker from '@/components/UI/Input/DatePicker.vue'
import { dateString, timeString, dateAndTimeToUTC } from '@/functions/formatDate'
import useApi from '@/hooks/useApi'
import UIInputLabel from '@/components/UI/Input/Label.vue'
import UISelectChauffeur from '@/components/UI/Select/Chauffeur.vue'
import UISelectWagen from '@/components/UI/Select/Wagen.vue'
import UISelectFixed from '@/components/UI/Select/Fixed.vue'

const props = defineProps({
  callback: Function,
  planning_id: Number,
  isOphaalLuchthaven: Boolean,
  editAll: Boolean,
  endpoint: {
    type: String,
    default: 'SHIFT_SAVE',
  },
  shift: {
    type: Object,
    default() {
      return {
        wagen_id: null,
        user_id: null,
        start: null,
        aangekomen: null,
        klant_ingestapt: null,
        klant_afgezet: null,
        einde: null,
        wachttijd: '00:00',
        is_no_show: false,
      }
    },
  },
})

const labelAangekomen = computed(() => props.isOphaalLuchthaven ? 'Aangekomen op luchthaven (ophaallocatie)' : 'Aangekomen op ophaallocatie')

const emit = defineEmits(['update:shift', 'close'])
const vModel = useVModel(props, 'shift', emit, { deep: true, passive: true })

const PREFILL_VALUE = props.editAll ? null : new Date()

const formTmp = {
  user_id: vModel.value.user_id || null,
  wagen_id: vModel.value.wagen_id || null,
  start: vModel.value.start || PREFILL_VALUE,
  aangekomen: vModel.value.aangekomen || (vModel.value.start ? PREFILL_VALUE : null),
  klant_ingestapt: vModel.value.klant_ingestapt || (vModel.value.aangekomen ? PREFILL_VALUE : null),
  klant_afgezet: vModel.value.klant_afgezet || (vModel.value.klant_ingestapt ? PREFILL_VALUE : null),
  einde: vModel.value.einde || (vModel.value.klant_afgezet ? PREFILL_VALUE : null),
  is_no_show: vModel.value.is_no_show || null,
  wachttijd: timeString(vModel.value.wachttijd || '00:00'),
}

const form = ref({
  user_id: formTmp.user_id || null,
  wagen_id: formTmp.wagen_id || null,

  start: !formTmp.start
    ? { date: null, time: null }
    : { date: dateString(formTmp.start), time: timeString(formTmp.start || '00:00') },

  aangekomen: !formTmp.aangekomen
    ? { date: null, time: null }
    : { date: dateString(formTmp.aangekomen), time: timeString(formTmp.aangekomen || '00:00') },

  klant_ingestapt: !formTmp.klant_ingestapt
    ? { date: null, time: null }
    : { date: dateString(formTmp.klant_ingestapt), time: timeString(formTmp.klant_ingestapt || '00:00') },

  klant_afgezet: !formTmp.klant_afgezet
    ? { date: null, time: null }
    : { date: dateString(formTmp.klant_afgezet), time: timeString(formTmp.klant_afgezet || '00:00') },

  einde: !formTmp.einde
    ? { date: null, time: null }
    : { date: dateString(formTmp.einde), time: timeString(formTmp.einde || '00:00') },

  is_no_show: formTmp.is_no_show,
  wachttijd: formTmp.wachttijd,
})

if (props.shift.start && props.shift.einde) {
  if (!props.shift.klant_ingestapt) form.value.klant_ingestapt = clone(form.value.start)
  if (!props.shift.klant_afgezet) form.value.klant_afgezet = clone(form.value.einde)
  if (!props.shift.aangekomen) {
    form.value.aangekomen = clone(form.value.klant_ingestapt)
  }
}

if (props.shift.is_no_show) {
  form.value.klant_afgezet = clone(form.value.klant_ingestapt)
}

const countFilled = computed(() => {
  if (props.editAll) return -1

  const shift = props.shift || {}
  return !!shift.start + !!shift.aangekomen + !!shift.klant_ingestapt + !!shift.klant_afgezet + !!shift.einde
})

const api = useApi()
const handleOpslaan = () => {
  const sendData = {
    id: props.shift?.id || props.shift?.shift_id || null,
    shift_id: props.shift?.id || props.shift?.shift_id || null,
    user_id: props.shift?.user_id || null,
    wagen_id: form.value.wagen_id || null,
    planning_id: props.planning_id || props.shift.planning_id,
    start: dateAndTimeToUTC(form.value.start.date, form.value.start.time || '00:00'),
    aangekomen: null,
    klant_ingestapt: null,
    klant_afgezet: null,
    einde: null,
    wachttijd: timeString(form.value.wachttijd || '00:00'),
    is_no_show: form.value.is_no_show || false,
  }

  if (props.editAll) {
    sendData.user_id = form.value.user_id
    sendData.aangekomen = dateAndTimeToUTC(form.value.aangekomen.date, form.value.aangekomen.time || '00:00')
    sendData.klant_ingestapt = dateAndTimeToUTC(form.value.klant_ingestapt.date, form.value.klant_ingestapt.time || '00:00')
    sendData.klant_afgezet = dateAndTimeToUTC(form.value.klant_afgezet.date, form.value.klant_afgezet.time || '00:00')
    sendData.einde = dateAndTimeToUTC(form.value.einde.date, form.value.einde.time || '00:00')
  } else {
    delete sendData.id
    delete sendData.shift_id
    if (props.shift.start) {
      sendData.aangekomen = dateAndTimeToUTC(form.value.aangekomen.date, form.value.aangekomen.time || '00:00')
      if (props.shift.aangekomen) {
        sendData.klant_ingestapt = dateAndTimeToUTC(form.value.klant_ingestapt.date, form.value.klant_ingestapt.time || '00:00')
        if (props.shift.klant_ingestapt) {
          sendData.klant_afgezet = dateAndTimeToUTC(form.value.klant_afgezet.date, form.value.klant_afgezet.time || '00:00')
          if (props.shift.klant_afgezet) {
            sendData.einde = dateAndTimeToUTC(form.value.einde.date, form.value.einde.time || '00:00')
          }
        }
      }
    }
  }


  if (sendData.is_no_show) {
    sendData.klant_afgezet = sendData.klant_ingestapt
  }

  const utcNow = dateAndTimeToUTC(dateString(new Date()), timeString(new Date()))
  if (sendData.start && sendData.start > utcNow) {
    return alert('Je kan geen datums/tijden in de toekomst opgeven voor je shift. Kijk je opgegeven datums en tijden na.')
  }
  if (sendData.aangekomen && sendData.aangekomen > utcNow) {
    return alert('Je kan geen datums/tijden in de toekomst opgeven voor je shift. Kijk je opgegeven datums en tijden na.')
  }
  if (sendData.klant_ingestapt && sendData.klant_ingestapt > utcNow) {
    return alert('Je kan geen datums/tijden in de toekomst opgeven voor je shift. Kijk je opgegeven datums en tijden na.')
  }
  if (sendData.klant_afgezet && sendData.klant_afgezet > utcNow) {
    return alert('Je kan geen datums/tijden in de toekomst opgeven voor je shift. Kijk je opgegeven datums en tijden na.')
  }
  if (sendData.einde && sendData.einde > utcNow) {
    return alert('Je kan geen datums/tijden in de toekomst opgeven voor je shift. Kijk je opgegeven datums en tijden na.')
  }

  if (sendData.start && sendData.aangekomen && sendData.start > sendData.aangekomen) {
    return alert('De opgegeven datums/tijden zijn niet chronologisch en dus ook verkeerd. Kijk je opgegeven datums en tijden na.')
  }
  if (sendData.aangekomen && sendData.klant_ingestapt && sendData.aangekomen > sendData.klant_ingestapt) {
    return alert('De opgegeven datums/tijden zijn niet chronologisch en dus ook verkeerd. Kijk je opgegeven datums en tijden na.')
  }
  if (sendData.klant_ingestapt && sendData.klant_afgezet && sendData.klant_ingestapt > sendData.klant_afgezet) {
    return alert('De opgegeven datums/tijden zijn niet chronologisch en dus ook verkeerd. Kijk je opgegeven datums en tijden na.')
  }
  if (sendData.klant_afgezet && sendData.einde && sendData.klant_afgezet > sendData.einde) {
    return alert('De opgegeven datums/tijden zijn niet chronologisch en dus ook verkeerd. Kijk je opgegeven datums en tijden na.')
  }

  api(props.endpoint, sendData).then(() => {
    vModel.value.wagen_id = sendData.wagen_id
    vModel.value.einde = sendData.einde
    vModel.value.aangekomen = sendData.aangekomen
    vModel.value.klant_ingestapt = sendData.klant_ingestapt
    vModel.value.klant_afgezet = sendData.klant_afgezet
    vModel.value.start = sendData.start
    vModel.value.wachttijd = sendData.wachttijd
    vModel.value.is_no_show = sendData.is_no_show

    if (typeof props.callback === 'function') {
      props.callback(sendData)
    }
    emit('close')
  })
}
</script>

<style scoped lang="scss">
.current--step {
  @apply bg-orange-100 border-2 border-orange-200 rounded-sm p-2 -mx-2;
}
</style>
