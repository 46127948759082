import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js'
import store from './store'

// import './plugins/vuetify'
import './assets/tailwind-custom.scss'
// import './assets/tailwind.css'
import './assets/pickers.css'
import './assets/pickers-custom.scss'
// import '@themesberg/flowbite'

// import registerComponents from './plugins/register-components'

import modalInstall from './plugins/modal'
import notificationInstall from './plugins/notification'
import axiosInstall from './plugins/axios'
import apexchartsInstall from './plugins/apexcharts'
import portalVueInstall from './plugins/portal'
import cleanStorage from './plugins/cleanStorage'
// import apolloInstall from './plugins/graphql'
import { createHead } from '@vueuse/head'

const head = createHead()
const VueApp = createApp(App)

// registerComponents(VueApp)
modalInstall(VueApp)
notificationInstall(VueApp)
axiosInstall(VueApp)
apexchartsInstall(VueApp)
portalVueInstall(VueApp)
// apolloInstall(VueApp)

VueApp.provide('router', router)

VueApp.use(router)
VueApp.use(store)
VueApp.use(head)
VueApp.mount('#app')
cleanStorage()
