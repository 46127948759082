import useWindow from '@/hooks/useWindow'

export default async () => {
  if (!useWindow()) return
  const localStorage = window.localStorage || {}
  const now = Date.now()
  for (var key in localStorage) {
    const value = localStorage.getItem(key)

    // Clean values of empty objects (defaults)
    if (value === '{}') {
      localStorage.removeItem(key)
      continue
    }

    // Clean old Directions data
    const directionsKey = process.env.VUE_APP_DIRECTIONS_STORAGE_VERSION
    if (key.indexOf('Directions-') === 0) {
      const version = String(key.split('-')[1])
      if (version !== directionsKey) {
        localStorage.removeItem(key)
      }
      continue
    }

    // Clean old 'Rit' & 'Offerte' form data
    if (key.indexOf('RitData-') === 0 || key.indexOf('OfferteData-') === 0) {
      const time = parseInt(key.split('-')[1], 10)
      if (time > 0 && now - time > 86400000) {
        localStorage.removeItem(key)
      }
      continue
    }
  }
}
