<template>
  <div v-if="model" class="flex flex-col gap-4">
    <UIInput :disabled="!isNew" v-model="model.email" label="E-mailadres" type="text" class="w-full max-w-sm" placeholder="E-mailadres ..." />
    <UIInput v-model="model.affiliate_slug" label="Affiliate slug" type="text" class="w-full max-w-sm" placeholder="Affiliate slug ..." />
    <UIInput v-model="model.affiliate_perc" label="Affiliate %" type="number" class="w-full max-w-sm" placeholder="Affiliate % ..." />
    <button @click="handleSubmit" class="btn success mt-4">Opslaan</button>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'
import { useVModel } from '@vueuse/core'

import UIInput from '@/components/UI/Input/Input'
import useApi from '@/hooks/useApi'

const props = defineProps({
  callback: Function,
  modelValue: {
    type: Object,
    default() {
      return {
        email: '',
        affiliate_slug: '',
        affiliate_perc: 3,
      }
    },
  },
})

const isNew = !props.modelValue.email && !props.modelValue.affiliate_slug
const api = useApi()
const emit = defineEmits(['update:modelValue'])
const model = useVModel(props, 'modelValue', emit, { deep: true, passive: true })

const handleSubmit = () => {
  api('AFFILIATE_UPSERT', model).then(() => {
    if (typeof props.callback === 'function') {
      props.callback()
    }
    emit('close', true)
  })
}
</script>
