<template>
  <UISelectModalNullable
    v-if="optionsTable"
    v-bind="$attrs"
    v-model="innerValue"
    :options="options"
    :optionsTable="optionsTable"
  />
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'
import { useStore } from 'vuex'
import { useVModel } from '@vueuse/core'

import UISelectModalNullable from '@/components/UI/Select/SelectModalNullable.vue'

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: null,
  },
  optionsTable: String,
})

const store = useStore()
const emit = defineEmits(['update:modelValue'])
const innerValue = useVModel(props, 'modelValue', emit, { passive: true, watch: true })

if (props.optionsTable?.length) {
  store.dispatch('API_CUSTOM_OPTIONS_SYNC', { table: props.optionsTable })
}

const options = computed(() => store.state[String(props.optionsTable)] || [])
</script>
