<template>
  <UILoading v-if="loadingOptions" />
  <div v-else class="flex flex-col gap-3">
    <UISelectFixed :options="options" class="w-full max-w-sm" label="Bank" v-model="form.institution_id" />
    <button :disabled="api.loading.value || loadingOptions" class="btn success inline-block" @click="handleOpslaan">Opslaan</button>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import useApi from '@/hooks/useApi'
import useGetApi from '@/hooks/useGetApi'

import UILoading from '@/components/UI/Loading.vue'
import UISelectFixed from '@/components/UI/Select/Fixed.vue'

const api = useApi()
const { data: options, loading: loadingOptions } = useGetApi('NORDIGEN_INSTITUTION_OPTIONS')

const form = ref({
  institution_id: '',
})

const handleOpslaan = async () => {
  const { data } = await api('NORDIGEN_REQUISITIONS_AANMAKEN', form)
  window.location.href = data?.link || window.location.href
}
</script>
