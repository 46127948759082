<template>
  <div v-if="!options?.length">
    <strong class="text-red-500">Unknown SelectFixed type: {{ type }}</strong>
    <pre>{{ { LABELS, OPTIONS, options } }}</pre>
  </div>
  <UISelectModal v-else-if="multiple" :required="required" multiple :label="typeof label !== 'undefined' ? label : LABELS[type]" :options="options" v-model="selected" v-bind="$attrs" />
  <UISelect v-else :required="required"  :label="typeof label !== 'undefined' ? label : LABELS[type]" :options="options" v-model="selected" v-bind="$attrs" />
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useVModel } from '@vueuse/core'
import { LABELS, default as OPTIONS } from '@/constants/OPTIONS'
import UISelect from '@/components/UI/Select/Select.vue'
import UISelectModal from '@/components/UI/Select/SelectModal.vue'

const props = defineProps({
  nullable: Boolean,
  nullableLabel: {
    type: String,
    default: 'Alles tonen',
  },
  all: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    required: false,
  },
  type: {
    type: String,
    validate(_val) {
      return Object.keys(OPTIONS).includes(String(_val || ''))
    },
    default: '',
  },
  multiple: Boolean,
  required: Boolean,
  options: Array,
  optionsParams: Object,
  prefixOptions: Array,
  suffixOptions: Array,
  modelValue: {
    type: [Number, String, Array],
  },
})

const nullableItem = ref({ label: props.nullableLabel, value: null, nullableItem: true })

const emit = defineEmits(['update:modelValue'])

const options = computed(() => {
  let options = []

  if (props.type && OPTIONS[props.type]) {
    if (typeof OPTIONS[props.type] === 'function') {
      const optionsProp = {
        all: props.all,
        ...(props.optionsParams || {}),
      }
      options = OPTIONS[props.type](optionsProp) || []
    } else if (Array.isArray(OPTIONS[props.type])) {
      options = OPTIONS[props.type]
    }
  } else {
    options = props.options || []
  }

  options = [].concat(props.prefixOptions || [], options, props.suffixOptions || [])
  if (props.nullable && options.findIndex(el => el.value === null) === -1) {
    options.unshift(nullableItem.value)
  }

  return options
})

const selected = useVModel(props, 'modelValue', emit, { deep: true, passive: true })
onMounted(() => {
  if (typeof selected.value === 'undefined' || selected.value === '') {
    if (props.multiple) {
      selected.value = []
      return
    }

    selected.value = props.nullable ? null : ''
  }
})
</script>
