<template>
  <div class="flex flex-col gap-4" v-if="vModelRit.id > 0">
    <div>
      <UIInput textarea v-model="form.planning_optie_heen" :label="vModelRit.is_retour ? 'Interne memo heen' : 'Interne memo'" rows="4" class="w-full mb-0 pb-0" placeholder="Interne memo heen ..." />
      <small class="text-xs text-gray-500 block">Deze memo geldt voor: {{ dateTimeString(vModelRit.vertrek_datetime) }}</small>
    </div>
    <div>
      <UIInput v-if="vModelRit.is_retour" textarea v-model="form.planning_optie_retour" label="Interne memo retour" rows="4" class="w-full mb-0 pb-0" placeholder="Interne memo retour ..." />
      <small class="text-xs text-gray-500 block" v-if="vModelRit.is_retour">Deze memo geldt voor: {{ dateTimeString(vModelRit.retour_datetime) }}</small>
    </div>
    <button :disabled="api.loading.value" class="btn success inline-block" @click="handleOpslaan">Opslaan</button>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue'
import { useVModel } from '@vueuse/core'
import UIInput from '@/components/UI/Input/Input'
import useApi from '@/hooks/useApi'
import { dateTimeString } from '@/functions/formatDate'

const props = defineProps({
  rit: {
    type: Object,
    required: true,
    default() {
      return {
        id: null,
        planning_optie_heen: null,
        is_retour: false,
        planning_optie_retour: null,
      }
    },
  },
})

const emit = defineEmits(['close', 'update:rit'])
const vModelRit = useVModel(props, 'rit', emit, { deep: true, passive: true })

const form = ref({
  id: props.rit.id,
  planning_optie_heen: props.rit.planning_optie_heen,
  planning_optie_retour: !props.rit.is_retour ? null : props.rit.planning_optie_retour,
})

const api = useApi()

const handleOpslaan = () => {
  api('RIT_MEMO', form).then(() => {
    vModelRit.value.planning_optie_heen = form.value.planning_optie_heen
    vModelRit.value.planning_optie_retour = form.value.planning_optie_retour
    emit('close')
  })
}
</script>
