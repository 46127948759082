<template>
  <UIFormModalShift
    endpoint="TOEKKENNING_BEWERKING"
    :callback="handleCallback"
    :planning_id="planning_id"
    :isOphaalLuchthaven="isOphaalLuchthaven"
    :shift="vModel"
    edit-all
  />
</template>

<script setup>
import { defineProps } from 'vue'
import { useVModel } from '@vueuse/core'
import UIFormModalShift from '@/components/Form/Modal/Shift.vue'

const props = defineProps({
  callback: Function,
  planning_id: Number,
  isOphaalLuchthaven: Boolean,
  modelValue: {
    type: Object,
    default() {
      return {
        planning_id: null,
        user_id: null,
        shift_id: null,
        wagen_id: null,
        start: null,
        aangekomen: null,
        klant_ingestapt: null,
        klant_afgezet: null,
        einde: null,
        chiron_status: null,
      }
    },
  },
})

const emit = defineEmits(['update:shift', 'close'])
const vModel = useVModel(props, 'modelValue', emit, { deep: true, passive: true })

const handleCallback = (data) => {
  if (typeof props.callback === 'function') {
    props.callback(data)
  }
  emit('close')
}
</script>
