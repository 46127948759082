import { onMounted, ref, inject, watch, unref, computed } from 'vue'
import API_ENDPOINTS from '@/constants/API_ENDPOINTS'

export default (_endpoint, apiData = {}, options = {}) => {
  let { watch: shouldWatch, apiProp, defaultLimit, defaultOffset, skipInitial } = options || {}
  apiProp = apiProp || 'list'

  let limit = ref(defaultLimit >= 0 ? defaultLimit : 10)
  let offset = ref(defaultOffset >= 0 ? defaultOffset : 0)
  let page = ref(1)
  let count = ref(0)
  let items = ref([])
  let data = ref({})
  const loading = ref(false)
  const touched = ref(false)

  const axios = inject('axios')

  const extraData = computed(() => unref(apiData))

  const getData = async () => {
    const endpoint = API_ENDPOINTS[_endpoint]?.endpoint || _endpoint
    const params = { limit: limit.value, offset: offset.value, ...extraData.value }
    loading.value = true
    const { data: resultData } = await axios.get(endpoint, { params })
      .catch(() => ({}))
      .finally(() => { loading.value = false })

    data.value = resultData || {}
    items.value = resultData[apiProp] || []
    count.value = resultData.count || (resultData[apiProp] || []).length
    touched.value = false
    return { count: count.value, items: items.value, data }
  }

  const reset = () => {
    page.value = 1
    offset.value = 0
    count.value = 0
    items.value = []
    loading.value = false
    touched.value = false
  }

  onMounted(() => {
    if (!skipInitial) {
      getData()
    }
    watch(() => `${Object.values(extraData.value).join('-')}`, () => {
      touched.value = true
    })
    watch(() => `${Object.values(shouldWatch ? extraData.value : {}).join('-')}${limit.value}${offset.value}`, getData)
  })

  return { limit, offset, count, items, data, touched, loading, reset, getData }
}
