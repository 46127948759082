<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <UIModal :key="debug" :title="notification?.title" :icon="icon" v-model="isOpen" :full-width="debug > 3" close @close="close" @click-icon="debug += 1" z-index="60">
    <div class="mt-2 relative">
      <p class="text-gray-600">
        <span v-if="notification?.html" v-html="notification?.html"></span>
        <template v-else>{{ notification?.content || '' }}</template>
      </p>

      <div class="mt-4 w-full text-sm max-w-full overflow-x-scroll" v-show="debug > 3">
        <pre class="text-gray-500">{{ notification }}</pre>
      </div>
    </div>
  </UIModal>
</template>

<script setup>
import { ref, inject, computed, watch } from 'vue'
import UIModal from '@/components/UI/Modal.vue'
import { useRoute } from 'vue-router'

let notification = inject('notification')
const isOpen = computed({
  get: () => notification?.value && Object.keys(notification.value || {}).length > 0,
  set: val => (notification.value = val),
})

const icon = computed(() => {
  if (!isOpen.value) return ''
  switch (notification.value.type || 'info') {
    case 'error':
      return 'text-red-500 fas fa-hexagon-exclamation fa-exclamation-hexagon'
    case 'warning':
      return 'text-yellow-500 fas fa-triangle-exclamation fa-exclamation-triangle'
    case 'success':
      return 'text-green-500 fas fa-check'
    default:
      return 'text-blue-500 fas fa-circle-info fa-info-circle'
  }
})

let debug = ref(0)

const close = () => {
  debug.value = 0
  notification.value = {}
}

const route = useRoute()
watch(
  route,
  (val, oldval) => {
    if (oldval?.query?.from) {
      if (oldval.query.from.includes(val.path)) return
      if (oldval.query.from.includes(val.fullPath)) return
    }
    if (val.fullPath !== oldval.fullPath) close()
  },
)
</script>

<style scoped lang="scss">
ul {
  @apply list-disc pl-5 space-y-1;
}
</style>
