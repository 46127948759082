<template>
  <p v-if="ritReedsBetaald" class="px-0 text-center text-red-500">Rit werd reeds volledig betaald!</p>
  <div v-else class="flex flex-col gap-3">
    <UISelectChauffeur v-if="store.getters.hasMasterPermission" class="w-full max-w-sm" active label v-model="betaling.user_id" />
    <UIInputDatePicker label="Betaal datum" class="text-left" v-model:date="betaling.date" />
    <UISelectFixed type="BETAALMETHODE" class="w-full max-w-sm" label="Betaalmethode" v-model="betaling.methode" />
    <div class="flex flex-col">
      <UIInput v-model="betaling.bedrag" @blur="() => betaling.bedrag = Math.min(betaling.bedrag, openSaldo)" label="Bedrag" type="number" :max="openSaldo" class="w-full max-w-sm" placeholder="Bedrag ..." />
      <small v-if="betaling.bedrag > openSaldo" class="text-sm">Openstaand saldo: &euro;&nbsp;{{ formatPrice(openSaldo) }}</small>
    </div>
    <UIInput textarea v-model="betaling.opmerking" label="Opmerking over de ontvangen betaling" rows="4" class="w-full max-w-sm" placeholder="Eventuele opmerking ..." />
    <button class="btn success inline-block" @click="handleOpslaan">Opslaan</button>
    <div class="flex flex-col justify-center text-center">
      <small class="text-indigo-400 italic"><b>Gegevens goed nakijken alvorens het opslaan!</b></small>
      <small class="italic text-sm">{{ betaling.methode }} ontvangen {{ betaling.bedrag }} EUR.</small>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, ref } from 'vue'
import UIInput from '@/components/UI/Input/Input'
import UIInputDatePicker from '@/components/UI/Input/DatePicker.vue'
import UISelectFixed from '@/components/UI/Select/Fixed'
import UISelectChauffeur from '@/components/UI/Select/Chauffeur.vue'
import { dateString } from '@/functions/formatDate'
import { formatPrice } from '@/functions/formatNumber'
import { useStore } from 'vuex'
import useApi from '@/hooks/useApi'
import { useVModel } from '@vueuse/core'

const props = defineProps({
  callback: Function,
  user_id: Number,
  rit: {
    type: Object,
    default() {
      return {
        prijs: 0,
        bedrag_betaald: 0,
        bedrag_ontvangen: 0,
        bedrag_open_saldo: 0,
        // datum - kan bij kalender
        // vertrek_datetime - kan bij alle andere gevallen
      }
    },
  },
})

const store = useStore()
const emit = defineEmits(['close', 'update:rit'])
const vModelRit = useVModel(props, 'rit', emit, { deep: true, passive: true })

// const ritPrijs = computed(() => Math.max(0, (props.rit?.bedrag_saldo || 0)))
const openSaldo = computed(() => Math.max(0, (props.rit?.bedrag_open_saldo || 0)))
const ritReedsBetaald = computed(() => openSaldo.value <= 0)

const betaling = ref({
  rit_id: props.rit.id,
  bedrag: props.rit ? Math.max(0, openSaldo.value) : 0,
  user_id: (props.user_id > 0 ? props.user_id : (store.getters.currentUserId || null)),
  date: dateString(props.rit?.datum || props.rit?.vertrek_datetime || new Date()),
  methode: 'CASH',
  opmerking: null,
})

const api = useApi()
const handleOpslaan = () => {
  if (!store.getters.hasMasterPermission) {
    betaling.user_id = store.getters.currentUserId
  }
  api('BETALING_CREATE', betaling).then(() => {
    vModelRit.value.bedrag_betaald = (vModelRit.value.bedrag_betaald || 0) + Number(betaling.value.bedrag)
    vModelRit.value.bedrag_ontvangen = (vModelRit.value.bedrag_ontvangen || 0) + Number(betaling.value.bedrag)
    vModelRit.value.bedrag_open_saldo = (vModelRit.value.bedrag_open_saldo || 0) - Number(betaling.value.bedrag)
    if (betaling.value.methode !== 'CASH') {
      vModelRit.value.is_enkel_cash_betaald = false
    }
    if (typeof props.callback === 'function') {
      props.callback()
    }
    emit('close')
  })
}
</script>
