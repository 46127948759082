<template>
  <aside v-bind="$attrs" class="z-30" :class="{ opened: modelValue }">
    <div class="mb-5 text-center" v-once>
      <img src="/cdn/img/logo/logo-512.png" alt="" class="w-10 lg:w-12 inline -mt-1" />
      <p class="text-white text-sm px-1 align-middle inline">Luchthavenvervoer Kevin</p>
    </div>
    <UISidebarMenu :menu="menuFiltered" @navigation="onNavigation" />
    <small class="block py-4 text-center text-gray-400">Build: {{ isDashboard() ? 'D' : 'K' }}-{{ buildVersion }}</small>
  </aside>
  <div v-show="modelValue" @click.self.stop="$emit('update:modelValue', false)" class="z-20 lg:hidden overlay" aria-hidden="true"></div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'
import menu from '@/constants/NAVIGATION_MENU'
import UISidebarMenu from '@/components/UI/Sidebar/Menu.vue'
import { hasRoutePathPermission } from '@/functions/hasRoutePermission'
import isDashboard from '@/functions/isDashboard'
import clone from 'just-clone'

const buildVersion = String(process.env.VUE_APP_BUILD_VERSION || '').trim()

const menuFiltered = computed(() => {
  const array = clone(menu).map(el => {
    const item = clone(el)
    if (item.submenu?.length) {
      item.submenu = item.submenu.filter(sub => sub.to && hasRoutePathPermission(sub.to))
      if (!item.submenu?.length) return null
    }
    if (item.to && !hasRoutePathPermission(item.to)) return null
    return item
  }).filter(el => !!el)

  return Object.freeze(array)
})

defineProps({
  modelValue: Boolean,
})

const emit = defineEmits(['update:modelValue'])
const onNavigation = () => {
  if (window.innerWidth < 1024) {
    emit('update:modelValue', false)
  }
}
</script>

<style lang="scss">
aside {
  background-color: #202935;
  // border-right: 1px solid #fefefe;
  @apply w-64 absolute z-50 left-0 top-0 transform h-screen overflow-y-scroll flex-shrink-0 transition-transform duration-200 ease-in-out -translate-x-64 py-4;

  &.opened {
    @apply lg:static left-auto top-auto translate-x-0 overflow-y-auto;
  }
}
</style>
