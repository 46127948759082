<template>
  <div :class="{ error }">
    <UIInputLabel :required="required" v-if="label" :class="labelClass" class="whitespace-nowrap">
      {{ label }}
    </UIInputLabel>
    <UISelectModal
      v-bind="$attrs"
      v-model="innerValue"
      :placeholder="placeholder"
      :format-label="formatLabel"
      :search-placeholder="searchPlaceholder || `${placeholder} ...`"
      :options="nullable ? [nullableItem, ...options] : [...options]"
    />
    <p v-if="error" class="text-red-500">{{ error }}</p>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue'
import UISelectModal from '@/components/UI/Select/SelectModal.vue'
import UIInputLabel from '@/components/UI/Input/Label.vue'
import { useVModel } from '@vueuse/core'

const props = defineProps({
  label: String,
  required: Boolean,
  error: String,
  options: Array,
  nullable: Boolean,
  formatLabel: Function,
  nullableLabel: {
    type: String,
    default: 'Alles tonen',
  },
  labelClass: {
    type: String,
    default: 'font-bold',
  },
  modelValue: {
    type: [String, Number],
    default: null,
  },
  placeholder: {
    type: String,
  },
  searchPlaceholder: {
    type: String,
    default: 'Zoeken / Filteren ...',
  },
})

const nullableItem = ref({ label: props.nullableLabel, value: null, nullableItem: true })

const emit = defineEmits(['update:modelValue'])
const innerValue = useVModel(props, 'modelValue', emit, { passive: true, watch: true })
emit('update:modelValue', innerValue.value)
</script>
