
import axios from 'axios'
import { computed, ref } from 'vue'

const currentVersion = ref(null)
const buildVersion = String(process.env.VUE_APP_BUILD_VERSION || '').replace(/\s/g, '').trim()

const setCurrentVersion = (version) => {
  currentVersion.value = String(version || '').replace(/\s/g, '').trim()
}

export const getCurrentVersion = async () => {
  return axios.get(`https://dashboard.taxiboeken.be/build.txt?_t=${Date.now()}`).then(({ data }) => {
    console.log({ currentVersion: data, buildVersion })
    setCurrentVersion(data)
    return data
  }).catch((error) => {
    setCurrentVersion('?')
    console.error({ error })
    return '?'
  })
}

export default () => {
  const isLatestVersion = computed(() => (
    !currentVersion.value
    || (String(currentVersion.value) == buildVersion)
    || !buildVersion
    || buildVersion?.toLowerCase() === 'development'
  ))

  return {
    isLatestVersion,
  }
}
